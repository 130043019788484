import React, { useState, useEffect } from "react";
import users from "../../assets/element/Eusers.png";
// import { FaArrowRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import MerchantBanner from "./merchantBanner";
import Testimonials from "../../views/Testimonials";
import FaqSection from "../../views/FaqSection";
import queryString from "query-string";
import axios from "../api/axios";
import { merchantTypes } from "./data";
import { Simplemodal } from "../helpers/modal/simplifiedmodal";
import { Paymentoption } from "./paymentoption";
import { useApp } from "../../hooks/useApp";
import Footer from "../../containers/Footer";
import NavBar from "../../containers/NavBar";
import { FiArrowUpRight } from "react-icons/fi";
const MigrationFeesPayment = () => {
  // eslint-disable-next-line
  let [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { country } = useApp();
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(merchantTypes);
  const [searchResult, setSearchResult] = useState("");

  // eslint-disable-next-line
  const [current, setCurrent] = useState("");
  const [prices, setPrices] = useState(null); // State to hold the fetched data
  // eslint-disable-next-line
  const [isSuccess, setIsSuccess] = useState(false);
  const [amt, setAmt] = useState(1234444); // State to hold the fetched data
  // eslint-disable-next-line
  const [formData, setFormData] = useState({});
  // eslint-disable-next-line
  const [customamount, setCustom] = useState("");
  const [paymentTitle, setPaymentTitle] = useState("checking");
  const [paymentService, setPaymentService] = useState("checking");

  useEffect(() => {
    const fetchpriceData = async () => {
      setIsSuccess(false);
      if (!country.currency) {
        try {
          const response = await axios.get(
            `/new/merchant-prices?country=NGN
           `
          );
          const myprice = response?.data?.data;
          setPrices(myprice);
          console.log(prices);
          // setIsSuccess(true);
        } catch (error) {
          // console.log(error);
          setIsSuccess(false);
        }
      } else {
        try {
          const response = await axios.get(
            `/new/merchant-prices?country=${country.currency}
           `
          );
          const myprice = response?.data?.data;
          setPrices(myprice);
          setIsSuccess(true);
        } catch (error) {
          setIsSuccess(false);
        }
      }
    };

    fetchpriceData();
  }, [country]);

  const exchangeRate = prices?.EXCHANGE_RATE;

  const { search } = useLocation();
  const values = queryString.parse(search);
  var tab = values.merchant && values.merchant.toUpperCase();

  const handlePayment = (value, custom = false) => {
    setFormData({
      type: value,
      custom,
    });

    // console.log(JSON.stringify(formData));
  };

  const toWholeCurrency = (num) =>
    (num / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  const getPrice = (name) => {
    return (
      prices &&
      (toWholeCurrency(prices[`${name}_USDCENTS`] / 11),
      toWholeCurrency(prices[`${name}_NGNKOBO`]))
    );
  };

  useEffect(() => {
    setTimeout(() => {
      if (tab) {
        var check = merchantTypes.find((item) => {
          return item.price === tab;
        });
        // console.log(prices);
        if (check && prices) {
          setCurrent(
            toWholeCurrency(prices[`${tab}_USDCENTS`] / 11),
            toWholeCurrency(prices[`${tab}_NGNKOBO`])
          );
        } else {
          console.log("");
        }
      }
    }, 500);
  }, [prices, tab]);

  // Function to handle search input change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const applyFilter = () => {
    if (searchValue.trim() === "") {
      setFilteredData(merchantTypes);
      setSearchResult("");
    } else {
      const filteredTitles = merchantTypes.filter((item) =>
        item.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      setSearchResult(filteredTitles.length > 0 ? "" : "Title not found");
      setFilteredData(filteredTitles);
    }
  };

  return (
    <>
      <NavBar />
      {/* <div className="  pb-16 w-full pt-24 lg:pt-36 lg:pb-32 "> */}
      <div className="w-full bg-[#070E42]  px-4 md:px-8 py-7 lg:pb-32 pb-16  lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-24 lg:pt-28  ">
        <div className="vlg:container  vlg:mx-auto">
          <MerchantBanner />
        </div>
      </div>
      <div className="px-4 md:px-8  lg:px-20 xxl:px-32  vlg:px-64 ">
        <div className="vlg:container  vlg:mx-auto pt-14 ">
          <div className="mb-12 text-center my-4">
            <h2 className="text-[32px] font-[550] text-[#14290A]">
              Migration Fee Payments{" "}
              <span className="text-vestigreen">Simplified!</span>
            </h2>
            <p className="font-[200] text-[20px] text-[#2B5219]">
              Think lifting a feather is effortless? Try payment with Vesti.
            </p>
            {/* <p>Selected Country: {selectedCountry}</p> */}

            <div className="w-full lg:w-[720px]  h-[70px] mt-6 border-[0.5px] border-[#676D7E] mx-auto rounded-[5px] flex flex-col justify-center">
              <div className="flex justify-between items-center">
                <input
                  type="text"
                  placeholder="Search by fee title..."
                  value={searchValue}
                  onChange={handleSearchChange}
                  className="p-2 border-[0.5px] rounded-md mx-2 w-full outline-none focus:bg-[#FCFFF9] focus:border-vestigreen"
                />
                {/* <p className="font-[300] ml-2">search</p> */}
                <button
                  onClick={applyFilter}
                  className="bg-[#66A848] w-[150px] h-[56px] text-white rounded-[5px] mr-2"
                >
                  search
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 gap-4">
            {filteredData.map(
              ({
                abbreviation,
                title,
                link,
                price,
                subtitle,
                service,
                noOfPayment,
                index,
              }) => {
                return (
                  <div
                    key={index}
                    className=" mb-3 p-4 w-full bg-merchantcardcolor border rounded-[12px] border-lightvestigreen"
                  >
                    <div className="flex items-center gap-4">
                      <p className="text-[12px] bg-[#dfffd0] text-center text-[#3e6f26] w-[40px] h-[40px] flex flex-col justify-center rounded-[100%]">
                        {abbreviation}
                      </p>
                      <p className="text-[#3e6f26] font-bold">{title}</p>
                    </div>

                    <div className="flex my-4">
                      <img src={users} alt="" className="w-[5rem]" />
                      <p className="flex text-[12px] items-center">
                        <span className="bg-[#dfffd0] text-center rounded-full text-[#3e6f26] w-[20px] h-[20px] flex flex-col justify-center mr-2">
                          {noOfPayment}+
                        </span>
                        Successful Payment
                      </p>
                    </div>

                    <div className="flex gap-2">
                      <p
                        className="text-vestigreen font-[500] text-[16px] flex items-center gap-1 cursor-pointer "
                        onClick={() => {
                          setShowModal(true);
                          setAmt(getPrice(price));
                          // setCurrent(getPrice(price));
                          setPaymentTitle(title);
                          setPaymentService(service);
                          handlePayment();
                        }}
                      >
                        Click to pay{" "}
                        <FiArrowUpRight className="cursor-pointer" />{" "}
                      </p>
                      <p className="text-vestigreen font-[500] text-[16px] border px-2 rounded-lg ml-2 border-[#00000040]">
                        {country.currency ? country.currency : "NGN"}{" "}
                        {getPrice(price)}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          {/* {console.log(paymentTitle)}
          {console.log("service", paymentService)} */}
          <p className="mt-2 text-red-600 text-center">{searchResult}</p>
        </div>
      </div>
      <Testimonials
        title={
          <>
            <h1 className="text-3xl text-headcol font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-[#2B5219] text-lg font-fontReg">
              Few out of 1000+ stories of Vesti Users
            </p>
          </>
        }
      />
      <FaqSection />

      <Simplemodal onClick={() => setShowModal(false)} open={showModal}>
        {/* <p>Hello i'm a new modal</p> */}
        <Paymentoption
          paymentTitle={paymentTitle}
          exchangeRate={exchangeRate}
          paymentService={paymentService}
          selectedCountry={country.currency}
          rate={prices && prices["MERCHANT_RATE"] / 100}
          toWholeCurrency={toWholeCurrency}
          amount={amt}
          setCustom={setCustom}
          closeModal={() => {
            setShowModal(false);
          }}
        />
      </Simplemodal>

      {/* {console.log(amt)} */}
      <Footer />
    </>
  );
};

export default MigrationFeesPayment;
