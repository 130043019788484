import React from 'react';
import './FloatingButton.scss'; 

const FloatingButton = ({onClick, title}) => {
  return (
    <div className="floating-button-container">
      <p onClick={onClick} className="floating-button">{title}</p>
    </div>
  );
};

export default FloatingButton;
