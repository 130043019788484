import React, { useState } from "react";
import Miainav from "./miainav";
import PostQuestion from "./postQuestion";
import ChatHistory from "./chatHistory";
import Typewriter from "typewriter-effect";

const Miailanding = () => {
  const [height, setHeight] = useState("100vh");
  const [smallheight, setSmallHeight] = useState("50vh");
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [answer, setAnswer] = useState("");
  const [minimize, setMinimize] = useState(false);
  const [chatId, setChatId] = useState("");

  const addMessage = (text, isUser = true) => {
    // setMessages((prevMessages) => [
    //   ...prevMessages,
    //   { text, isUser, examTime },
    // ]);
    const newMessage = {
      text,
      isUser,
      // Set examTime only if it's not already present
      examTime: messages.length === 0 ? new Date() : undefined,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const generateChatbotResponse = (userInput) => {
    return (
      <Typewriter
        onInit={(typewriter) => {
          typewriter
            .typeString(
              answer
                ? answer
                : "What are your primary reasons for wanting to migrate?"
            )
            .pauseFor(1000) // Optional: pause for 1 second after typing

            .start();
        }}
      />
    );
  };

  return (
    <div
      className={`fixed z-[10000] bg-[#050E42] w-[19rem] lg:w-[24rem] bottom-0 right-0 h-[${smallheight}] lg:h-[${height}]  flex flex-col justify-between transition-height duration-500 ease-in-out overflow-hidden`}
    >
      <div>
        <Miainav
          setHeight={setHeight}
          height={height}
          setSmallHeight={setSmallHeight}
          smallheight={smallheight}
        />

        {height !== "100vh" ? (
          <div className="h-[10%] hidden md:block">
            <ChatHistory
              inputText={inputText}
              addMessage={addMessage}
              generateChatbotResponse={generateChatbotResponse}
              messages={messages}
              setMessages={setMessages}
              answer={answer}
              height={height}
              smallheight={smallheight}
            />
          </div>
        ) : (
          <div className="h-[100%]">
            <ChatHistory
              inputText={inputText}
              addMessage={addMessage}
              generateChatbotResponse={generateChatbotResponse}
              messages={messages}
              setMessages={setMessages}
              answer={answer}
              height={height}
              smallheight={smallheight}
            />
          </div>
        )}
      </div>

      <div className="p-[20px]">
        <PostQuestion
          inputText={inputText}
          setInput={(e) => setInputText(e.target.value)}
          addMessage={addMessage}
          generateChatbotResponse={generateChatbotResponse}
          messages={messages}
          setMessages={setMessages}
          setInputText={setInputText}
          answer={answer}
          setAnswer={setAnswer}
          chatId={chatId}
          setChatId={setChatId}
          smallheight={smallheight}
        />
      </div>
    </div>
  );
};
export default Miailanding;
