import Techstars1 from "../assets/logo-icons/techstars1.svg";
import Techcity1 from "../assets/logo-icons/techcity1.svg";
import Bloomberg1 from "../assets/logo-icons/bloomberg1.svg";
import CareerImage from "../assets/images/career-image.png";
import Button from "../components/Button";
import { openLink } from "../helpers/helpers";

const CareersBanner = () => {
  return (
    <div className="w-full bg-secondary">
      <div className="py-32 container mx-auto flex flex-col lg:flex-row lg:justify-between lg:items-center">
        <div className="lg:w-[45%]">
          <img src={CareerImage} alt="career-pics" />
        </div>
        <div className="lg:w-[45%]">
          <h1 className="text-[#ffffff] font-semibold text-5xl pb-4 font-fontSemi">
            Careers at Vesti
          </h1>
          <p className="text-[#fff] text-xl pb-12 font-fontReg">
            Work in a place you love solving problems you are passionate about.
          </p>
          <Button link="#" CTA="See Open Roles" styles="p-4" width="50%" />

          {/* // <button className="bg-[#67A948] p-4 rounded-xl w-72 text-[#ffffff]">
          //   See Open Roles
          // </button> */}

          <div className="pt-16 flex flex-wrap items-center opacity-50">
            <img className="" src={Techstars1} alt="techstars" onClick={()=> openLink('https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022')}/>
            <img className="px-8" src={Techcity1} alt="techcity" onClick={()=> openLink('https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/')}/>
            <img className="" src={Bloomberg1} alt="bloomberg" onClick={()=> openLink('https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians')}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersBanner;
