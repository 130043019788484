import React from "react";
import elemententerprenuer from "../../assets/element/aboutElement.jpg";

/* xs: "414px", sm: "640px", md: "720px", */

export const Elemententerprenuer = () => {
  return (
    <section className="w-full px-4 pb-6 pt-[4rem] md:px-8 lg:pt-[6rem] lg:px-[8rem] xxl:px-32 vlg:px-64  ">
      <div className="flex flex-col gap-8 sm:flex-row vlg:container vlg:mx-auto justify-between items-center">
        <div className="w-full sm:w-[60%] hidden sm:block">
          <img src={elemententerprenuer} alt="" />
        </div>

        <article className="w-full md:w-[40%]  md:h-[30rem]">
          <p className="text-[#8B8C8C] text-[13px] xxl:text-[16px] font-[400]">
            Your Financial Transformation Begins Now
          </p>
          <h1 className="text-[22px] my-4 leading-[32px]  md:leading-[42px]  font-[600] md:text-[38px] xxl:text-[42px] text-[#20082B]">
            About <span className="text-[#6F368B]">Element by</span> Vesti
            {/* <span className="text-[#6F368B]">by entrepreneurs</span> */}
          </h1>
          <article className="text-[16px]  xxl:text-[16px]">
            <p className="text-[#20082B] font-fontLight">
              Element is the ultimate solution designed exclusively for
              visionary business owners, entrepreneurs, and leaders across the
              globe.
            </p>

            <p className="mt-6 text-[#20082B] font-fontLight">
              It offers users elevated possibilities. Seamlessly open a free US
              bank account, get access to a virtual and physical card, send and
              add money with ease. Seamlessly monitor your expenses and keep
              your budget on track.
            </p>
          </article>

          <div className=" sm:hidden mt-6">
            <img src={elemententerprenuer} alt="" className="  w-full" />
          </div>
        </article>
      </div>
    </section>
  );
};
