import React from "react";
import { Button } from "@material-tailwind/react";
// import history from "../../assets/images/history.svg";

const History = (props) => {
  return (
    <div className="lg:w-[100%] flex flex-col items-center justify-center">
      <div className="m-4 sm:m-8 p-8 bg-secondary rounded-lg mb-8 items-center lg:w-[90%] ">
        <div className="lg:flex lg:justify-between ">
          <div className="lg:w-[70%] flex flex-col items-center sm:flex-row sm:items-center sm:w-full">
            <img
              src={props.icon}
              alt={props.alt}
              className="mb-2 sm:mb-0 sm:mr-4 w-20 h-20"
            />
            <div className="bg-[#060E42] rounded-lg w-full sm:w-[70%] lg:w-full p-2">
              <p className="text-xl text-white font-fontReg lg:mt-4">
                {props.title}
              </p>
            </div>
          </div>
          <div className="lg:w-[50%] flex justify-center sm:w-full ">
            <Button
              onClick={() => props.setOpen(true)}
              className="font-fontLight text-base capitalize p-4 w-[80%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
            >
              {props.buttonTitle}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
