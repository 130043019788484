import { OVTestimony } from "../../constant/TestimonialsData";

const VisaTestimony = ({ title }) => {
  const getRandomItemstestimonialData = () => {
    const shuffled = OVTestimony.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  };
  return (
    <div className="w-full pt-24 ">
      <div className="container mx-auto flex flex-col items-center bg-[#F6F7FF] w-[80%] p-[4.5rem]">
        <div className="font-fontSemi text-center">{title}</div>
        {/* Comments and Testimonies */}
        <div className="pt-12 pb-20 grid lg:grid-cols-2 md:grid-cols-2 gap-7 font-fontLight">
          {getRandomItemstestimonialData().map((testi, i) => (
            <div
              key={i}
              className="border-2 border-black-300 h-90 lg:h-70 rounded-xl px-7 py-5"
              style={{ backgroundColor: testi.backgroundColor }}
            >
              <div className="flex pb-3">
                <div>
                  <img
                    src={testi.image}
                    alt="person"
                    className="w-10 h-10 rounded-full"
                  />
                </div>
                <div className="px-3">
                  <p className="text-headcol text-lg font-fontReg">
                    {testi.name}{" "}
                    <span className="text-sm">{testi.subtitle}</span>
                  </p>
                  <p className="text-xs font-fontLight text-[#4F4F4F]">
                    {testi.date}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-headcol text-sm font-fontLight">
                  {testi.testimony}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VisaTestimony;
