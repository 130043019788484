export const merchantTypes = [
  {
    abbreviation: "SV",
    title: "US - SEVIS FEE",
    link: "SEVIS",
    price: "SEVIS",
    subtitle: "Pay your SEVIS Fee easily",
    service: "SEVIS",
    noOfPayment: 20,
  },

  {
    abbreviation: "USV",
    title: "USA VISA",
    link: "USVisaPayments/USVISA_DS160",
    price: "USVISADS160",
    subtitle: "",
    service: "USVISADS160",
    noOfPayment: 60,
  },

  {
    abbreviation: "TEF",
    title: "TEF",
    link: "TEF",
    price: "TEF",
    subtitle: "You can pay for your TEF training fees here.",
    service: "TEF",
    noOfPayment: 50,
  },

  {
    abbreviation: "WES",
    title: "WES",
    link: "WES",
    price: "WES",
    subtitle: "Pay your WES Fee easily",
    service: "WES",
    noOfPayment: 20,
  },

  {
    abbreviation: "IHS",
    title: "IHS UK",
    link: "UKStudentVisaPayments/IhsFee",
    price: "IHSUK",
    subtitle:
      "Congratulations on your UK University Admission. Get started by paying your IHS fees.",
    service: "IHSUK",
    noOfPayment: 20,
  },

  {
    abbreviation: "IELTS",
    title: "IELTS",
    link: "IELTS",
    price: "IELTS",
    subtitle: "You can pay for your IETLS training fees here.",
    service: "IELTS",
    noOfPayment: 40,
  },

  {
    abbreviation: "UKSV",
    title: "UK Student Visa",
    link: "UKStudentVisaPayments/ukStudentVisa",
    price: "UKSTUDENTVISA",
    subtitle:
      "Congratulations on your UK University Admission. You can pay your UK Student Visa Application fees in Naira.",
    service: "UKSTUDENTVISA",
    noOfPayment: 30,
  },

  {
    abbreviation: "NMC",
    title: "NMC UK",
    link: "USVisaPayments/NMCUK",
    price: "NMCUK",
    subtitle: "",
    service: "NMCUK",
    noOfPayment: 10,
  },

  {
    abbreviation: "TLS",
    title: "TLS",
    link: "UKStudentVisaPayments/priorityProcessing",
    price: "PRIORITY",
    subtitle:
      "TLScontact works with governments from around the world to provide visa and consular services on their behalf to travellers and citizens.\n This priority fee will ensure your application is prioritized over 7 working days",
    service: "PRIORITY",
    noOfPayment: 20,
  },

  {
    abbreviation: "EDU",
    title: "Educational Pathway",
    link: "",
    price: "EDUCATIONPATHWAY",
    subtitle: "",
    service: "EDUCATIONPATHWAY",
    noOfPayment: 10,
  },

  {
    abbreviation: "UKWV",
    title: "Uk Skilled Worker Visa",
    link: "",
    price: "UKSKILLEDWORKERVISA",
    subtitle: "",
    service: "UKSKILLEDWORKERVISA",
    noOfPayment: 20,
  },
];

// ACCELERATED_PASSPORT_APPLICATION: 4000;
// CARD_EXCHANGE_RATE: 63000;
// CARD_FUNDING_RATE: 95800;
// EDUCATIONPATHWAY_NGNKOBO: 32305747;
// EDUCATIONPATHWAY_USDCENTS: 33899;
// EXCHANGE_RATE: 95800;
// IELTS_NGNKOBO: 29080795;
// IELTS_USDCENTS: 30515;
// IHSUK_NGNKOBO: 102352200;
// IHSUK_USDCENTS: 107400;
// MERCHANT_RATE: 95300;
// MONO_CARD_RATE: 95800;
// MONO_LIQUIDATION_RATE: 66000;
// NAIRA_TO_USD_RATE: 95800;
// NMCUK_NGNKOBO: 20640074;
// NMCUK_USDCENTS: 21658;
// OUTSIDE_LAGOS_PRICE: 250000;
// PRIORITY_NGNKOBO: 32402000;
// PRIORITY_USDCENTS: 34000;
// REFERRAL_BONUS: 10000;
// REFERRAL_CARD_BONUS: 30000;
// REFERRAL_SERVICE_BONUS: 50000;
// SEVIS_NGNKOBO: 38120000;
// SEVIS_USDCENTS: 40000;
// TEF_NGNKOBO: 5718000;
// TEF_USDCENTS: 6000;
// TOTAL_USER_WITH_VIRTUAL_CARDS_CREATED: 1009;
// TOTAL_VIRTUAL_CARDS_CREATED: 5573;
// UKSKILLEDWORKERVISA_NGNKOBO: 34593900;
// UKSKILLEDWORKERVISA_USDCENTS: 36300;
// UKSTUDENTVISA_NGNKOBO: 52415000;
// UKSTUDENTVISA_USDCENTS: 55000;
// USD_TO_NAIRA_CENT: 67000;
// USD_To_NAIRA_CENT: 67000;
// USVISADS160_NGNKOBO: 19265848;
// USVISADS160_USDCENTS: 20216;
// WESECA_NGNKOBO: 22538450;
// WESECA_USDCENTS: 23650;
// WESEXPEDITED_NGNKOBO: 25921600;
// WESEXPEDITED_USDCENTS: 27200;
// WES_NGNKOBO: 20232190;
// WES_USDCENTS: 21230;
// WITHIN_LAGOS_PRICE: 180000;
