import React from "react";

const MerchantBanner = () => {
  return (
    <div className="pt-12">
      <h2 className="text-[42px] md:text-[52px] text-white leading-[110%] font-[550]">
        Make <span className="text-vestigreen">Migration Fee</span> <br />{" "}
        Payments In Just <br /> <span className="text-vestigreen">Minutes</span>
      </h2>
      <p className="w-fulll md:w-[520px] text-white text-[18px] leading-[150%] mt-6">
        Want To Migrate To Live, For School or For Work ? conveniently pay your,
        SEVIS fee, WES fees, visa fees, IHS fees and numerous migration fees
        without hassle.
      </p>
    </div>
  );
};

export default MerchantBanner;
