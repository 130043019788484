import { IoIosArrowDown } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import ProductsDropdown from "./ProductsDropdown";
import { useState } from "react";
import plane from "../assets/flight/flight 1.png";

const NavLinks = ({ addStyle, open, setOpen }) => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const toggleProducts = () => {
    setShow(!show);
  };

  return (
    <>
      <ul
        className={`${addStyle} bg:gap-10 mt-4 items-start gap-y-10  font-[400] text-[14px]`}
      >
        <li className="group">
          <NavLink
            onClick={toggleProducts}
            className="flex items-center font-[400] relative cursor-pointer hover:text-vestigreen"
          >
            <p className="pr-1">Products</p>
            <IoIosArrowDown />
          </NavLink>
          <div className={`pl-6 bg:hidden ${show ? "visible" : "hidden"}`}>
            <ul className="flex flex-col gap-y-4">
              <li>
                <NavLink
                  onClick={() => setOpen()}
                  to="/globalgeng-card"
                  className="text-base font-fontLight hover:text-vestigreen hover:font-fontSemi"
                >
                  GlobalGeng Card
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setOpen()}
                  to="/virtual-card"
                  className="text-base font-fontLight hover:text-vestigreen hover:font-fontSemi"
                >
                  Virtual Dollar Card
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setOpen()}
                  to="/wallets"
                  className="text-base font-[400] hover:text-vestigreen hover:font-fontSemi"
                >
                  Vesti Wallets
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setOpen()}
                  to="/migration-services"
                  className="text-base font-[400] hover:text-vestigreen hover:font-fontSemi"
                >
                  Migration Services
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={() => setOpen()}
                  to="/element"
                  className="text-base font-[400] hover:text-vestigreen hover:font-fontSemi"
                >
                  Element by Vesti
                </NavLink>
              </li>

              {/* <li>
                <NavLink
                  onClick={() => setOpen()}
                  to="#"
                  className="text-base font-fontLight hover:text-vestigreen hover:font-fontSemi"
                >
                  Vesti Flight
                </NavLink>
              </li> */}
            </ul>
          </div>
          <div className="absolute hidden bg:block bg:translate-y-[-800%] bg:group-hover:translate-y-0 bg:transition bg:delay-500 bg:ease-in-out">
            <ProductsDropdown setOpen={setOpen} />
          </div>
        </li>
        <li>
          <NavLink
            onClick={() => setOpen(false)}
            to="/about-us"
            className={` hover:text-vestigreen font-[400] hover:font-fontSemi ${
              splitLocation[1] === "about-us" &&
              `lg:border-b-[6px] lg:border-[#67A948] text-vestigreen`
            }`}
            // className="hover:text-vestigreen hover:font-fontSemi"
          >
            Company
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => setOpen(false)}
            to="/migration-fries"
            className={` hover:text-vestigreen font-[400] hover:font-fontSemi ${
              splitLocation[1] === "migration-fries" &&
              `lg:border-b-[6px] lg:border-[#67A948] text-vestigreen`
            }`}
            // className="hover:text-vestigreen hover:font-fontSemi"
          >
            Migration Fries
          </NavLink>
        </li>

        <li>
          <NavLink
            onClick={() => setOpen(false)}
            to="/flight"
            className={` hover:text-vestigreen font-[400] hover:font-fontSemi flex items-center gap-2 ${
              splitLocation[1] === "/" &&
              `lg:border-b-[6px] lg:border-[#67A948] text-vestigreen`
            }`}
          >
            Book a Flight
            <img src={plane} alt="" />
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default NavLinks;

// lg:hidden lg:group-hover:block
