import img1 from "../../assets/flight/londoonBrigde.png";
import img2 from "../../assets/flight/nairobicit.jpg";
import img3 from "../../assets/flight/abujatolondon.png";
import img4 from "../../assets/flight/lagostoacra.png";
import img5 from "../../assets/flight/toronto.jpg";
import img6 from "../../assets/flight/ugandaWildlife.jpg";

export const MyflightOffer = [
  {
    id: 4,
    img: img4,

    title: "Lagos to Accra",
    text: (
      <>
        Economy, One way Commissionable tickets are available through Airpeace
        Airline for this upcoming flight.
      </>
    ),
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "Nov. 3, 2023 - Nov. 15, 2023",
    price: "₦335,710.00",
  },

  {
    id: 1,
    img: img1,
    title: "Lagos to London",
    text: "Economy, One way Commissionable tickets are available through Kenya Airways for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "Nov. 09, 2023 - Jan. 26, 2024",
    price: "₦740,126.00",
  },
  {
    id: 2,
    img: img2,
    title: "Lagos to Nairobi",
    text: "Economy, Return Commissionable tickets are available through Uganda Airlines for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "Oct. 26, 2023 - Nov. 4, 2023",
    price: "₦684,022.00",
  },
  {
    id: 3,
    img: img3,
    title: "Abuja to London",
    text: "Economy, One way Commissionable tickets are available through Rwand Air for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "Nov. 12, 2023 - Jan. 20, 2024",
    price: "₦905,045.00",
  },

  {
    id: 5,
    img: img5,

    title: "Lagos to Toronto",
    text: "Economy, One way Commissionable tickets are available through Qatar Airways for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "Nov. 14, 2023 - Jan. 21, 2024",
    price: "₦1,031,663.00",
  },
  {
    id: 6,
    img: img6,

    title: "Lagos to Entebee",
    text: "Economy, One way Commissionable tickets are available through Uganda Airlines for this upcoming flight.",
    buttonText: "Book your flight now",
    offer: "Mid-Week Deal",
    date: "Oct. 19, 2023 - Oct. 26, 2023",
    price: "₦463,126.00",
  },

  // {
  //   id: 8,
  //   img: img8,

  //   title: "Lagos to Washington",
  //   text: "Economy, One way Commissionable tickets are available through Egypt Air for this upcoming flight.",
  //   buttonText: "Book your flight now",
  //   offer: "Mid-Week Deal",
  //   date: "Nov. 06, 2023 - Nov. 25, 2023",
  //   price: "₦1,036,114.00",
  // },

  // {
  //   id: 7,
  //   img: img7,
  //   title: "Nairobi City Tour",
  //   subTitle:
  //     "Experience the thrill of a Nairobi or Mombasa City Tour with convenient pick-up, followed by 6 nights of luxurious hotel accommodation, daily breakfast, and hassle-free access with a Kenya e-visa.",
  //   text: "Explore Nairobi or Mombasa with tours, enjoy 6 luxurious nights, daily breakfast, and seamless entry via a Kenya e-visa!",
  //   buttonText: "Book your flight now",
  //   offer: "Adventure",

  //   date: "Oct. 20, 2023 - Oct. 26, 2023",
  // },
];
