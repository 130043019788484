import debounce from "lodash/debounce";

export const objectValuesStringify = (obj) => Object.values(obj).join(", ");

export const roundToXDigits = (value, digits) => {
  if (!digits) {
    digits = 2;
  }
  value = value * Math.pow(10, digits);
  value = Math.round(value);
  value = value / Math.pow(10, digits);
  return value;
};

export const formatAmount = (amount, n, x) => {
  const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return amount.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};

export const getCurrency = (cur) => {
  switch (cur) {
    case "GBP":
    case "gbp":
      return "£";
    case "USD_CENTS":
    case "USD":
      return "$";
    case "CAD_CENTS":
      return "C$";
    case "NGN_KOBO":
    case "NGN":
    case "ngn":
      return "₦";
    case "CEDIS":
    case "cedis":
    case "Cedis":
    case "GHS":
    case "ghs":
      return "GH₵";
    case "zmw":
    case "ZMW":
    case "KWACHA":
    case "kwacha":
    case "Kwacha":
      return "ZMW";
    default:
      return "";
  }
};

export const getCurrencyName = (cur) => {
  switch (cur) {
    case "USD_CENTS":
      return "USD";
    case "CAD_CENTS":
      return "CAD";
    case "NGN_KOBO":
    case "ngn":
      return "Naira";
    case "GHC":
    case "ghc":
    case "ghs":
      return "Cedis";
    case "ZMW":
    case "zmw":
      return "Kwacha";
    case "GBP":
    case "gbp":
      return "Pounds";
    default:
      return "";
  }
};

export const toWholeCurrency = (num) => num.toLocaleString();

export const errorMessage = (error) => {
  if (error?.data?.errors) {
    return objectValuesStringify(error?.data?.errors);
  } else {
    return error?.data?.message || error.message;
  }
};

export const pathwayActionLink = (value) => {
  switch (value) {
    case "Virtual Call Meeting":
      return "Schedule Meeting";
    case "Pay for Initial Evaluation":
      return "Schedule Meeting";
    case "Apply for Proof of Fund":
      return "Apply For Proof Of Fund";
    default:
      return value;
  }
};

export const openLink = (value) => {
  window.open(value);
};

export const openMail = (value) => {
  window.location.href = `mailto:${value}`;
};

export const removeCommaAmount = (value) => {
  return parseFloat(
    value
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/,/g, "")
  );
};

export const returnAmount = (value) => {
  return value === "NaN" ? 0 : value.toLocaleString("en-US");
};

export const virtualBankFullName = (value) => {
  return value.split("(")[1].split(")")[0];
};

export const getInitials = (name) => {
  var initials = name.split(" ");
  initials = initials[0].substring(0, 1) + initials[1].substring(0, 1);
  return initials;
};

export const shuffleArray = (arr) => {
  return !arr ? [] : arr.sort(() => 0.5 - Math.random());
};
export const formatterUSD = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const getUsdDepositRate = (amount) => {
  var fivePerc = amount * 0.05;
  return fivePerc < 1000 ? fivePerc : 1000;
};

export const getUsdAchRate = (amount) => {
  // var perc = formatterUSD.format(amount * 0.013)
  var perc = amount * 0.013;
  return perc > 5 ? 5 : removeCommaAmount(perc);
};

export const getNGNRate = (amount) => {
  // var perc = formatterUSD.format(amount * 0.013)
  var perc = amount * 0.015;
  return perc > 2000
    ? 2100
    : amount >= 2500
    ? removeCommaAmount(perc + 100)
    : removeCommaAmount(perc);
};

export const getCardRate = (amount, curr) => {
  var perc = () => {
    switch (curr?.toLocaleLowerCase()) {
      case "ngn_kobo":
      case "ngn":
        return amount * 0.015 > 2000
          ? 2100
          : amount >= 2500
          ? removeCommaAmount(amount * 0.015 + 100)
          : removeCommaAmount(amount * 0.015);
      case "cedis":
      case "ghs":
      case "ghc":
        return amount * 0.02 > 2000
          ? 2100
          : amount >= 2500
          ? removeCommaAmount(amount * 0.015 + 100)
          : removeCommaAmount(amount * 0.015);
      case "ZMW":
      case "zmw":
        var fivePerc = amount * 0.05;
        return fivePerc < 1000 ? fivePerc : 1000;
      case "GBP":
      case "gbp":
        var fivePerc = amount * 0.05;
        return fivePerc < 1000 ? fivePerc : 1000;
      default:
        return 1;
    }
  };

  return perc();
};

export const getUsdWireRate = (amount) => {
  // var formatterUSD = new Intl.NumberFormat('en-US');
  if (amount > 0 && amount <= 10000) {
    return removeCommaAmount(formatterUSD.format(amount * 0.013 + 25));
  } else if (amount > 10000 && amount <= 100000) {
    return removeCommaAmount(formatterUSD.format(amount * 0.01 + 25));
  } else {
    return removeCommaAmount(formatterUSD.format(amount * 0.008 + 25));
  }
};

export const getUsdWireperc = (amount) => {
  if (amount > 0 && amount <= 10000) {
    return ` 1.3%`;
  } else if (amount > 10000 && amount <= 100000) {
    return ` 1%`;
  } else {
    return ` 0.8%`;
  }
};

export const nameInUppercase = (value) => {
  return !value ? "" : value.charAt(0).toUpperCase() + value.slice(1);
};

export const getCurrencyCode = (cur) => {
  switch (cur) {
    case "USA":
    case "United States":
      return "USD";
    case "NGA":
    case "Nigeria":
      return "NGN";
    case "GHA":
    case "Ghana":
      return "GHS";
    case "ZMB":
    case "Zambia":
      return "ZMW";
    case "GBR":
    case "UK":
    case "United Kingdom":
      return "GBP";
    case "KEN":
    case "Kenya":
      return "KES";
    default:
      return "";
  }
};

export const getPhoneCode = (cur) => {
  switch (cur) {
    case "United States":
      return "+1";
    case "Nigeria":
      return "+234";
    case "Ghana":
      return "+233";
    case "Zambia":
      return "+260";
    case "United Kingdom":
      return "+44";
    case "Kenya":
      return "+254";
    default:
      return "";
  }
};

export const getCurrencyImg = (cur) => {
  switch (cur) {
    case "GH":
    case "ghs":
      // return 'https://upload.wikimedia.org/wikipedia/commons/4/40/Flag_of_Ghana.png';
      return "https://upload.wikimedia.org/wikipedia/commons/4/40/Flag_of_Ghana.png";
    case "NG":
    case "ngn":
      // return 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg';
      return "https://res.cloudinary.com/wevesti/image/upload/v1698480453/stgcyigpbiadb2g6ipjj.jpg";
    case "ZM":
    case "zmw":
    case "kwacha":
      // return 'https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg';
      return "https://res.cloudinary.com/wevesti/image/upload/v1698480509/l6crbhlwgcrgk1axwa9j.jpg";
    case "MXW":
    case "Pesos":
    case "pesos":
      // return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Mexico.svg/1600px-Flag_of_Mexico.png';
      return "https://res.cloudinary.com/wevesti/image/upload/v1699076452/xihtkljb4qi7rfrgcygv.png";
    case "UK":
    case "gbp":
      // return 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg';
      return "https://res.cloudinary.com/wevesti/image/upload/v1698480604/s5f9gmx0d9ujzxqmgrgt.jpg";
    default:
      return "";
  }
};

export const debounceApiCall = debounce((searchTerm) => {
  // Make API call with the search term
}, 1000);
