import React, { useState } from "react";
import { Flightbanner } from "./flightbanner";
import NavBar from "../../containers/NavBar";
import { Flightoffer } from "./flightoffer";
import { Flightvirtualcardadvert } from "./flightvirtualcardadvert";
import { Subscribe } from "./subscribe";
import FaqSection from "../../views/FaqSection";
import Footer from "../../containers/Footer";
import Testimonials from "../../views/Testimonials";
import { RadioOption } from "../inputs/radiobutton.js";
import { Simplemodal } from "../helpers/modal/simplifiedmodal.js";
import { Platformbutton } from "../helpers/button/button.js";
import ngnwallet from "../../assets/icons/ngnwallet.svg";

const FlightLandingPage = () => {
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [option, setOption] = useState("NGN");

  const setPaymentOption = (value) => {
    setOption(value);
  };

  return (
    <div>
      <Simplemodal
        onClick={() => setShowCurrencyModal(false)}
        open={showCurrencyModal}
      >
        <div className="paymentoption-container">
          <div className="paymentoption-inner">
            <div>
              {/* <h2 className="text-vestigreen">{` Step 1 of 2`}</h2> */}
              <h2 className="font-[600] text-[24px]  text-[#14290A]">
                Select Currency
              </h2>
              <p className="font-[300] text-[#2B5219]">
                Select the currency you want to pay with
              </p>
            </div>

            <div className="paymentoption-inner mt-8 center mb-8">
              <RadioOption
                image={ngnwallet}
                changed={setPaymentOption}
                id="1"
                isSelected={option === "NGN"}
                label="Pay in Naira"
                sublabel="Pay for your flight in Naira"
                value="NGN"
              />
              <div className="mb-4"></div>
              <RadioOption
                image={ngnwallet}
                changed={setPaymentOption}
                id="2"
                isSelected={option === "USD"}
                label="Pay in USD"
                value="USD"
                sublabel="Pay for your flight in Dollars"
              />

              <div className="mb-4"></div>
            </div>

            {option === "NGN" && (
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  window.open(" https://app.wevesti.com/bookflight", "_blank");
                  setShowCurrencyModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            )}

            {option === "USD" && (
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  window.open(
                    " https://app.wevesti.com/bookflight/usd",
                    "_blank"
                  );
                  setShowCurrencyModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            )}
          </div>
        </div>
      </Simplemodal>
      <div className=" ">
        <NavBar />
      </div>

      <div className=" ">
        <Flightbanner setShowCurrencyModal={setShowCurrencyModal} />
        <Flightoffer setShowCurrencyModal={setShowCurrencyModal} />
        <Flightvirtualcardadvert />

        <Testimonials
          title={
            <>
              <h1 className="text-3xl text-headcol font-fontSemi text-center">
                What our users are{" "}
                <span className="text-vestigreen">saying</span>
              </h1>
              <p className="text-center text-[#2B5219] text-lg font-fontReg">
                Few out of 1000+ stories of Vesti Users
              </p>
            </>
          }
        />
        <Subscribe />
        <FaqSection />
        <Footer />
      </div>
    </div>
  );
};

export default FlightLandingPage;
