import React, { useState } from "react";

import Select from "react-select";
import { components } from "react-select";
import "./singleselect.scss";
import { Profiletitle } from "./titlesubtitle/titlesubtitle";
const { SingleValue, Option } = components;
// import 'react-select/dist/react-select.css';

export const Singleselect = (props) => {
  // eslint-disable-next-line
  const [show, setShow] = useState(false);
  const style = {
    control: (base, state, isFocused) => ({
      ...base,
      height: "60px",
      backgroundColor: isFocused ? "#67A948" : "#F9F8F9",
      border: isFocused ? ".5px solid #67A948" : ".5px solid #CCCCCC",
      boxShadow: "0 !important",
      "&:hover": {
        border: ".5px solid #CCCCCC",
      },
      tabIndex: 1,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#151617",
        backgroundColor: isFocused ? "#F9F8F9" : "",
        borderTop: "1px solid #F8F6F5",
        lineHeight: "3em",
        display: "flex",
        alignItems: "center",
        zIndex: 9999,
      };
    },
  };

  return (
    <div
      className=" select-input "
      tabIndex="1"
      onFocus={() => setShow(true)}
      onBlur={() => setShow(false)}
    >
      {props.label && props.sublabel && (
        <Profiletitle
          label={props.label}
          sublabel={props.label && `Edit ${props.label}`}
        />
      )}
      <div
        className="select-box"
        style={{
          position: "relative",
          display: "inline-block",
          width: 100 + "%",
        }}
      >
        <Select
          id="color"
          className="select"
          placeholder={props.placeholder}
          options={props.options}
          value={props.value}
          onChange={props.onChange}
          styles={style}
          components={
            props.components
              ? props.components
              : {
                  IndicatorSeparator: () => null,
                }
          }
        />
        {props.children}
      </div>
    </div>
  );
};

export const IconSingleValue = (props) => (
  <SingleValue {...props} className="flex items-center justify-start">
    <img
      src={props.data.image}
      style={{ height: "15px", marginRight: "10px" }}
      alt="Flags"
    />
    {props.data.label}
  </SingleValue>
);

export const IconOption = (props) => (
  <Option {...props} className=" flex items-center justify-start">
    <img
      src={props.data.image}
      style={{ height: "15px", marginRight: "10px" }}
      alt="Flags"
    />
    {props.data.label}
  </Option>
);
