import React, { useState } from "react";
import axios from "../api/axios";

const PostQuestion = (props) => {
  const Handlepost = async () => {
    props.addMessage(props.inputText, true);

    const payload = {
      question: props.inputText,
      chatId: "67c884b0-0f4f-4475-8853-4c5e3512b9fc",
    };

    props.setInputText("");
    axios
      .post("https://syca-staging.wevesti.com/api/v1/bot/chat-no-auth", payload)
      .then((response) => {
        setTimeout(() => {
          const res = props.generateChatbotResponse(
            response.data?.data?.data?.answer
          );
          props.addMessage(res, false);
        }, 500);
        props.setAnswer(response.data?.data?.data?.answer);
      })
      .catch((error) => {
        // console.log(error);
        props.setInputText("");
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "return") {
      Handlepost();
    }
  };

  return (
    <div
      className={` flex flex-col justify-end lg:h-[15vh] ${
        props.smallheight === "50vh" ? "h-[15vh]" : "h-[0vh] overflow-hidden"
      } `}
    >
      {/* h-[20vh] */}
      <div className="border-[1.5px] text-[#fff] text-sm border-[#fff] p-4 rounded-[10px] w-full flex items-center gap-6">
        {/* <p>postQuestion</p> */}
        <input
          className="w-full bg-transparent"
          style={{
            outline: "none", // Remove the default focus/active outline

            color: "#fff", // Set the color of the placeholder
            "::placeholder": {
              color: "#fff", // Set the color of the placeholder text
            },
          }}
          type="text"
          placeholder="Write your message here"
          label=""
          value={props.inputText}
          onChange={props.setInput}
          onKeyPress={handleKeyPress}
        />
        <div className="cursor-pointer" onClick={() => Handlepost()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M19.1694 8.69468L1.60495 0.126635C1.45389 0.0529486 1.28802 0.0146484 1.11994 0.0146484C0.508968 0.0146484 0.0136719 0.509945 0.0136719 1.12091V1.15284C0.0136719 1.30128 0.0318733 1.44917 0.0678767 1.59319L1.75273 8.33256C1.79875 8.51666 1.95439 8.65265 2.14296 8.67362L9.5484 9.49645C9.80522 9.52496 9.99949 9.74201 9.99949 10.0005C9.99949 10.2589 9.80522 10.476 9.5484 10.5045L2.14296 11.3273C1.95439 11.3483 1.79875 11.4843 1.75273 11.6684L0.0678767 18.4077C0.0318733 18.5518 0.0136719 18.6997 0.0136719 18.8481V18.88C0.0136719 19.491 0.508968 19.9863 1.11994 19.9863C1.28802 19.9863 1.45389 19.948 1.60495 19.8743L19.1694 11.3062C19.6686 11.0628 19.9853 10.5559 19.9853 10.0005C19.9853 9.44498 19.6686 8.93816 19.1694 8.69468Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div className="mt-4">
        <p className=" text-[11px] leading-[18px] text-white">
          Miai does not make warranties about the completeness and accuracy of
          this information.
          {/* Thus, Miai assumes no responsibility or liability
            for any errors or omissions in the content . */}
        </p>
      </div>
    </div>
  );
};

export default PostQuestion;
