import React, { useState, useEffect } from "react";
import ElementBook from "../../assets/element/ELementBook_.png";
import EIcon from "../../assets/element/ebookIcon.svg";
import {
  ElementSimplemodal,
  Notificationmodal,
} from "../helpers/modal/simplifiedmodal";
import { ElementInputfloat } from "../inputs/inputfloat";
import { Phonenumber } from "../inputs/phoneinput";
import {
  getPhoneCode,
  getCurrencyCode,
  getCurrencyImg,
} from "../../helpers/utils";
import { allCountries } from "../helpers/countryregion";
import { Singleselect } from "../helpers/singleselect";
import axios from "../api/axios";
import successImg from "../../assets/element/successellipses.png";
import EbookM from "../../pages/Ebook";

const Ebook = () => {
  const [formModal, setFormModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpenModal(true);
    }, 3000); // 60000 milliseconds = 1 minute

    return () => clearTimeout(timeoutId);
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleContinue = async () => {
    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      country: selectedCountry.label,
      currencyFlag: getCurrencyCode(selectedCountry.label),
      service: "element-ebook",
      phoneNumber: {
        code: getPhoneCode(selectedCountry.label),
        number: phoneNumber.slice(getPhoneCode(selectedCountry.label).length),
      },
      currency: getCurrencyCode(selectedCountry.label),
    };

    axios
      .post(
        "https://syca-staging.wevesti.com/api/v1/general-services/actionable-signup",
        payload
      )
      .then((response) => {
        // console.log(response);
        setFirstName("");
        setLastName("");

        setPhoneNumber("");
        setSelectedCountry("");
        setFormModal(false);
        setSuccessModal(true);
      })
      .catch((error) => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setSelectedCountry("");
        setFailedModal(true);
      });
  };

  return (
    <div>
      <Notificationmodal onClick={() => setOpenModal(false)} open={openModal}>
        <EbookM setOpenModal={setOpenModal} />
      </Notificationmodal>

      <ElementSimplemodal onClick={() => setFormModal(false)} open={formModal}>
        <div className=" text-[16px] font-[600]">
          <h2>Enter your details</h2>

          <div className="mt-12 flex flex-col gap-10">
            <div className="flex gap-4">
              <ElementInputfloat
                label="firstname"
                type="text"
                name="firstname"
                value={firstName}
                disabled={false}
                placeholder="Austin"
                onChange={(e) => setFirstName(e.target.value)}
              />

              <ElementInputfloat
                label="lastname"
                type="text"
                name="lastname"
                value={lastName}
                disabled={false}
                placeholder="Duke"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <ElementInputfloat
                label="email"
                type="text"
                name="email"
                value={email}
                disabled={false}
                placeholder="austin@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <Singleselect
              label="Country"
              value={selectedCountry}
              options={allCountries.map((country) => ({
                value: country.countryShortCode,
                label: country.countryName,
              }))}
              onChange={handleCountryChange}
              placeholder="Select Country"
            />

            <Phonenumber
              value={phoneNumber}
              country=""
              setValue={(value) => setPhoneNumber(value)}
            />

            <div
              className="bg-[#330059] cursor-pointer rounded-[5px] py-[20px] text-center w-full text-[#fff] font-[500]"
              onClick={handleContinue}
            >
              Continue
            </div>
          </div>
        </div>
      </ElementSimplemodal>

      <ElementSimplemodal
        onClick={() => setSuccessModal(false)}
        open={successModal}
      >
        <div className="w-full flex flex-col items-center justify-between">
          <img src={successImg} className="w-[7rem] h-[7rem]" alt="success" />

          <p className="mt-8 text-[#4B0082] text-[22px]"> Success</p>
          <p className="mt-1 text-[#414244] text-[14px]">
            {" "}
            A link has been sent to{" "}
            <span className="text-[#4B0082]">{email}</span>
          </p>

          <div
            onClick={() => {
              setSuccessModal(false);
            }}
            className=" mt-24 border w-full cursor-pointer text-[14px] xlg:text-[16px] font-[600] bg-[#4B0082] text-[#fff] border-[#000] p-4 rounded-[10px]"
          >
            <p className="text-center">Okay</p>
          </div>
        </div>
      </ElementSimplemodal>

      {/* <ElementSimplemodal
        onClick={() => setFailedModal(false)}
        open={failedModal}
      >
        Failed
      </ElementSimplemodal> */}
      <section className="w-full px-4 pb-6 pt-[4rem] md:px-8 lg:pt-[6rem] lg:px-[8rem] xxl:px-32 vlg:px-64  ">
        <div className="flex bg-[#F7EDFF] p-[20px] sm:p-[50px] flex-col gap-8 bg:flex-row vlg:container vlg:mx-auto justify-between items-center">
          <div className="w-full bg:w-[60%] hidden bg:block">
            <img className="image-container" src={ElementBook} alt="" />
          </div>

          <div className="w-full bg:w-[40%]">
            <h1 className="text-[#D090FF] text-[22px] my-4 leading-[32px]  md:leading-[36px] font-[550] md:text-[24px] ">
              Free Ebook:{" "}
              <span className="text-[#4B0082]">
                Take Your Startup to
                <br className="hidden xlg:block" /> the Next Level with Proven
                Tips
              </span>
            </h1>

            <div className="flex items-center gap-6 mt-8">
              <img className="w-[40px] sm:w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col  gap-2 ">
                <h1 className="font-[600]">Start strong</h1>
                <p className="font-fontLight text-[14px] xlg:text-[16px]">
                  Lay a solid foundation with a well-defined business plan and
                  your value proposition.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-6 mt-8">
              <img className="w-[40px] sm:w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col  gap-2">
                <h1 className="font-[600]">Have a customer-centric approach</h1>
                <p className="font-fontLight text-[14px] xlg:text-[16px]">
                  Focus on customer feedback and iterate when necessary to meet
                  their needs.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-6 mt-8">
              <img className="w-[40px] sm:w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col  gap-2">
                <h1 className="font-[600]">Leverage technology</h1>
                <p className="font-fontLight text-[14px] xlg:text-[16px]">
                  Implement scalable technology solutions to streamline
                  operations
                </p>
              </div>
            </div>

            <div className="flex items-center gap-6 mt-8">
              <img className="w-[40px] sm:w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col  gap-2">
                <h1 className="font-[600]">Expand your market</h1>
                <p className="font-fontLight text-[14px] xlg:text-[16px]">
                  Target new demographics and explore international markets
                  through a U.S. business registration with Element.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-6 mt-8">
              <img className="w-[40px] sm:w-[56px]" src={EIcon} alt="" />

              <div className="flex flex-col gap-2">
                <h1 className="font-[600]">Learn continuously</h1>
                <p className="font-fontLight text-[14px] xlg:text-[16px]">
                  Lay a solid foundation with a well-defined business plan and
                  your value proposition.
                </p>
              </div>
            </div>

            <div className="w-full  bg:hidden mt-20">
              <img className="image-container" src={ElementBook} alt="" />
            </div>

            <div
              onClick={() => {
                setFormModal(true);
              }}
              className=" mt-12 border w-full cursor-pointer text-[14px] xlg:text-[16px] font-[600] hover:bg-[#330059] hover:text-[#fff] border-[#000] p-4 rounded-[10px]"
            >
              <p className="text-center">Download now</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ebook;
