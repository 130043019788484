import React from "react";
import Miai from "../../assets/miai/miaiImg.svg";

const Miainav = (props) => {
  const minimize = () => {
    props.setHeight("20vh");
    props.setSmallHeight("6vh");
  };

  const maximize = () => {
    props.setHeight("100vh");
    props.setSmallHeight("50vh");
  };
  return (
    <div className="h-[12vh] bg-[#16216B] flex flex-col justify-end pb-3 vlg:pb-8">
      <div className=" px-[15px]  pt-10  text-sm text-[#fff] flex justify-between items-center">
        <div className="flex items-center gap-2">
          <img className="w-[25px] h-[25px]" src={Miai} alt="Miai" />
          {/* <p>Miai (Pronounced Maya)</p> */}
          <p>
            Miai<sup className="text-[14px]">&trade;</sup> (Pronounced Maya)
          </p>
        </div>

        <div className="cursor-pointer">
          {props.height === "100vh" ? (
            <div
              onClick={() => {
                minimize();
              }}
            >
              <p>Minimize</p>
            </div>
          ) : (
            <div
              onClick={() => {
                maximize();
              }}
            >
              <p>Maximize</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Miainav;
