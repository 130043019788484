export const SchoolsData = [
  {
    id: 1,
    name: "A. T. Still University of Health Sciences",
    address: "Missouri",
    country: "United States",
  },
  {
    id: 2,
    name: "Adelphi University",
    address: "New York",
    country: "United States",
  },
  {
    id: 3,
    name: "Albany College of Pharmacy and Health Sciences",
    address: "New York",
    country: "United States",
  },
  {
    id: 4,
    name: "Albany Medical College",
    address: "North Carolina",
    country: "United States",
  },
  {
    id: 5,
    name: "Allen College",
    address: "Iowa",
    country: "United States",
  },
  {
    id: 6,
    name: "American University",
    address: "DC",
    country: "United States",
  },
  {
    id: 7,
    name: "American University of Antigua (AUA) College of Medicine",
    address: "New York",
    country: "United States",
  },
  {
    id: 8,
    name: "Amherst College",
    address: "Massachusetts",
    country: "United States",
  },

  {
    id: 9,
    name: "Appalachian State University",
    address: "North Carolina",
    country: "United States",
  },
  {
    id: 10,
    name: "Arcadia University",
    address: "Pennsylvania",
    country: "United States",
  },

  {
    id: 11,
    name: "Arizona State University",
    address: "Arizona",
    country: "United States",
  },
  {
    id: 12,
    name: "Arizona State University- Downtown Phoenix",
    address: "Arizona",
    country: "United States",
  },

  {
    id: 13,
    name: "Arizona State University- Polytechnic",
    address: "Arizona",
    country: "United States",
  },
  {
    id: 14,
    name: "Arizona State University- Skysong",
    address: "Arizona",
    country: "United States",
  },
  {
    id: 15,
    name: "Arizona State University- West",
    address: "Arizona",
    country: "United States",
  },
  {
    id: 16,
    name: "Auburn University",
    address: "Alabama",
    country: "United States",
  },
  {
    id: 17,
    name: "Babson College",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 18,
    name: "Ball State University",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 19,
    name: "Barnard College",
    address: "New York",
    country: "United States",
  },
  {
    id: 20,
    name: "Baruch College of the City University of New York",
    address: "New York",
    country: "United States",
  },
  {
    id: 21,
    name: "Bates College",
    address: "Maine",
    country: "United States",
  },
  {
    id: 22,
    name: "Baylor College of Medicine",
    address: "Texas",
    country: "United States",
  },
  {
    id: 23,
    name: "Baylor University",
    address: "Texas",
    country: "United States",
  },
  {
    id: 24,
    name: "Bellin College",
    address: "Wisconsin",
    country: "United States",
  },
  {
    id: 25,
    name: "Belmont University",
    address: "Tennessee",
    country: "United States",
  },
  {
    id: 26,
    name: "Bentley University",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 27,
    name: "Berry College",
    address: "Georgia",
    country: "United States",
  },
  {
    id: 28,
    name: "Biola University",
    address: "California",
    country: "United States",
  },
  {
    id: 29,
    name: "Bon Secours Memorial College of Nursing",
    address: "Virgina",
    country: "United States",
  },
  {
    id: 30,
    name: "Boston College",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 31,
    name: "Boston University",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 32,
    name: "Bowdoin College",
    address: "Maine",
    country: "United States",
  },
  {
    id: 33,
    name: "Bradley University",
    address: "Illnois",
    country: "United States",
  },
  {
    id: 34,
    name: "Brandeis University",
    address: "Massachusetts",
    country: "United States",
  },

  {
    id: 35,
    name: "Brigham Young University – Provo | BYU",
    address: "Utah",
    country: "United States",
  },
  {
    id: 36,
    name: "Brock University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 37,
    name: "Brown University",
    address: "Rhode Island",
    country: "United States",
  },
  {
    id: 38,
    name: "Bryan College of Health Sciences",
    address: "Nebraska",
    country: "United States",
  },
  {
    id: 39,
    name: "Bryant University",
    address: "Rhode Island",
    country: "United States",
  },
  {
    id: 40,
    name: "Bryn Mawr College",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 41,
    name: "Bucknell University",
    address: "Pennsylvania",

    country: "United States",
  },
  {
    id: 42,
    name: "Butler University",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 43,
    name: "California Institute of Technology",
    address: "California",
    country: "United States",
  },
  {
    id: 44,
    name: "California Polytechnic State University-San Luis Obispo - Cal Poly",
    address: "California",
    country: "United States",
  },
  {
    id: 45,
    name: "California State University - Fullerton",
    address: "California",
    country: "United States",
  },
  {
    id: 46,
    name: "California State University - Long Beach",
    address: "California",
    country: "United States",
  },
  {
    id: 47,
    name: "California State University - Los Angeles",
    address: "California",
    country: "United States",
  },
  {
    id: 48,
    name: "California State University Maritime Academy",
    address: "California",
    country: "United States",
  },
  {
    id: 49,
    name: "Calvin College",
    address: "Michigan",
    country: "United States",
  },
  {
    id: 50,
    name: "Carleton College",
    address: "Minnesota",
    country: "United States",
  },
  {
    id: 51,
    name: "Carleton University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 52,
    name: "Carnegie Mellon University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 53,
    name: "Case Western Reserve University",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 54,
    name: "Catholic University of America",
    address: "DC",
    country: "United States",
  },
  {
    id: 55,
    name: "Centra College of Nursing",
    address: "Colorado",
    country: "United States",
  },
  {
    id: 56,
    name: "Centra College ",
    address: "Kentucky",
    country: "United States",
  },
  {
    id: 57,
    name: "Chapman University",
    address: "California",
    country: "United States",
  },
  {
    id: 58,
    name: "Chatham University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 59,
    name: "Claremont Graduate University",
    address: "California",
    country: "United States",
  },
  {
    id: 60,
    name: "Claremont McKenna College",
    address: "California",
    country: "United States",
  },
  {
    id: 61,
    name: "Clark University",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 62,
    name: "Clarkson College",
    address: "Nebraska",
    country: "United States",
  },
  {
    id: 63,
    name: "Clarkson University",
    address: "New York",
    country: "United States",
  },
  {
    id: 64,
    name: "Clemson University",
    address: "South Carolina",
    country: "United States",
  },
  {
    id: 65,
    name: "Colby College",
    address: "Maine",
    country: "United States",
  },
  {
    id: 66,
    name: "Colgate University",
    address: "New York",
    country: "United States",
  },
  {
    id: 67,
    name: "College of Charleston",
    address: "South Carolina",
    country: "United States",
  },
  {
    id: 68,
    name: "College of the Holy Cross",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 69,
    name: "College of William and Mary",
    address: " Virginia ",
    country: "United States",
  },
  {
    id: 70,
    name: "Colorado College",
    address: "Colorado",
    country: "United States",
  },
  {
    id: 71,
    name: "Colorado School of Mines",
    address: "Colorado",
    country: "United States",
  },
  {
    id: 72,
    name: "Colorado State University-Fort Collins",
    address: "California",
    country: "United States",
  },
  {
    id: 73,
    name: "Columbia College of Nursing",
    address: "Wisconsin",
    country: "United States",
  },
  {
    id: 74,
    name: "Columbia University",
    address: "New York  ",
    country: "United States",
  },
  {
    id: 75,
    name: "Connecticut College",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 76,
    name: "Cooper Union for the Advancement of Science and Art",
    address: "New York",
    country: "United States",
  },
  {
    id: 77,
    name: "Cornell University",
    address: "New York",
    country: "United States",
  },
  {
    id: 78,
    name: "Creighton University",
    address: "Nebraska ",
    country: "United States",
  },
  {
    id: 79,
    name: "CUNY City College ",
    address: "Clemson Unive",
    country: "United States",
  },
  {
    id: 80,
    name: "Dalhousie University    ",
    address: "Nova Scotia",
    country: "Canada",
  },
  {
    id: 81,
    name: "Dartmouth College",
    address: "New Hampshire",
    country: "United States",
  },
  {
    id: 82,
    name: "Davidson College",
    address: "New York",
    country: "United States",
  },
  {
    id: 83,
    name: "Denison University",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 84,
    name: "Denver College of Nursing",
    address: "Colorado",
    country: "United States",
  },
  {
    id: 85,
    name: "DePaul University",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 86,
    name: "Depauw University",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 87,
    name: "Des Moines University-Osteopathic Medical Cente        ",
    address: "Iowa",
    country: "United States",
  },
  {
    id: 88,
    name: "DeSales University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 89,
    name: "Dickinson College",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 90,
    name: "Dordt College ",
    address: "Iowa",
    country: "United States",
  },
  {
    id: 91,
    name: "Drake University ",
    address: "Iowa",
    country: "United States",
  },
  {
    id: 92,
    name: "Drexel University",
    address: "Pennsylvania ",
    country: "United States",
  },
  {
    id: 93,
    name: "Duke University ",
    address: "North Carolina ",
    country: "United States",
  },
  {
    id: 94,
    name: "Duquesne University  ",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 95,
    name: "Eastern Virginia Medical School  ",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 96,
    name: "Edgewood College",
    address: "Wisconsin",
    country: "United States",
  },
  {
    id: 97,
    name: "Elon University ",
    address: "North Carolina",
    country: "United States",
  },
  {
    id: 98,
    name: "Emerson College",
    address: "Massachusetts  ",
    country: "United States",
  },
  {
    id: 99,
    name: "Emory University   ",
    address: "Georgia",
    country: "United States",
  },
  {
    id: 100,
    name: "Fairfield University",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 101,
    name: "Florida Atlantic University",
    address: "Florida",
    country: "United States",
  },
  {
    id: 102,
    name: "Florida International University",
    address: "Florida",
    country: "United States",
  },
  {
    id: 103,
    name: "Florida State University - FSU",
    address: "Florida",
    country: "United States",
  },
  {
    id: 104,
    name: "Fordham University",
    address: "New York",
    country: "United States",
  },
  {
    id: 105,
    name: "Franciscan Missionaries of Our Lady University",
    address: "Louisiana",
    country: "United States",
  },
  {
    id: 106,
    name: "Franklin and Marshall College",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 107,
    name: " Frontier Nursing University",
    address: "Kentucky",
    country: "United States",
  },
  {
    id: 108,
    name: "Furman",
    address: "South Carolina",
    country: "United States",
  },
  {
    id: 109,
    name: "Gannon University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 110,
    name: "George Mason University",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 111,
    name: "George Washington University - GWU",
    address: "DC",
    country: "United States",
  },
  {
    id: 112,
    name: "Georgetown University",
    address: "DC",
    country: "United States",
  },
  {
    id: 113,
    name: "Georgia Institute of Technology - Georgia Tech ",
    address: "Georgia",
    country: "United States",
  },
  {
    id: 114,
    name: "Gettysburg College",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 115,
    name: "Goldfarb School of Nursing at Barnes-Jewish College",
    address: "Missouri",
    country: "United States",
  },
  {
    id: 116,
    name: "Gonzaga University",
    address: "Washington",
    country: "United States",
  },
  {
    id: 117,
    name: "Grand Valley State University ",
    address: "Michigan",
    country: "United States",
  },
  {
    id: 118,
    name: "Grinnell College",
    address: "Iowa",
    country: "United States",
  },
  {
    id: 119,
    name: "Hamilton College ",
    address: "New York",
    country: "United States",
  },
  {
    id: 120,
    name: "Harvard University",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 121,
    name: "Harvey Mudd College",
    address: "California ",
    country: "United States",
  },
  {
    id: 122,
    name: "Haverford College",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 123,
    name: "High Point University",
    address: "North Carolina",
    country: "United States",
  },
  {
    id: 124,
    name: "Hofstra University",
    address: "New York",
    country: "United States",
  },
  {
    id: 125,
    name: "Howard University",
    address: "DC",
    country: "United States",
  },
  {
    id: 126,
    name: "Hult International Business School",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 127,
    name: "Hunter College of the City - University of New York",
    address: "New York",
    country: "United States",
  },
  {
    id: 128,
    name: "Illinois Institute of Technology",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 129,
    name: "Illinois State University",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 130,
    name: "Indiana University - Bloomington",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 131,
    name: "Indiana University-Purdue University-Indianapolis",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 132,
    name: "INSEAD - France start",
    address: "Paris",
    country: "France",
  },
  {
    id: 133,
    name: "INSEAD - Singapore start",
    address: "1 Ayer Rajah Ave",
    country: "Singapore",
  },
  {
    id: 134,
    name: "Iowa State University",
    address: "Iowa  ",
    country: "United States",
  },
  {
    id: 135,
    name: "James Madison University - JMU",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 136,
    name: "Jefferson College of Health Sciences",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 137,
    name: "John Carroll University",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 138,
    name: "Johns Hopkins University",
    address: "Maryland",
    country: "United States",
  },
  {
    id: 139,
    name: "Jones Graduate School of Business (Rice University)",
    address: " Texas",
    country: "United States",
  },
  {
    id: 140,
    name: "Kansas State University",
    address: "Kansas",
    country: "United States",
  },
  {
    id: 141,
    name: "Keck Graduate Institute",
    address: "California",
    country: "United States",
  },
  {
    id: 142,
    name: "Kenyon College",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 143,
    name: "Kettering College",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 144,
    name: "La Salle University",
    address: "Philadelphia",
    country: "United States",
  },
  {
    id: 145,
    name: "Lafayette College",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 146,
    name: "Lakehead University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 147,
    name: "Lakeview College of Nursing",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 148,
    name: "Lancaster General College of Nursing & Health Sciences",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 149,
    name: "Lehigh University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 150,
    name: "Lesley University",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 151,
    name: "Lipscomb University",
    address: "Tennessee",
    country: "United States",
  },
  {
    id: 152,
    name: "Loma Linda University",
    address: "California",
    country: "United States",
  },
  {
    id: 153,
    name: "Louisiana State University - LSU",
    address: "Louisiana",
    country: "United States",
  },
  {
    id: 154,
    name: "Louisiana State University Health Sciences Center-New Orleans",
    address: "Louisiana",
    country: "United States",
  },
  {
    id: 155,
    name: "Loyola Marymount University",
    address: "California",
    country: "United States",
  },
  {
    id: 156,
    name: "Loyola University Maryland",
    address: "Maryland",
    country: "United States",
  },
  {
    id: 157,
    name: "Loyola University New Orleans",
    address: "Louisiana",
    country: "United States",
  },
  {
    id: 158,
    name: "Loyola University of Chicago",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 159,
    name: "Macalester College",
    address: "Michigan",
    country: "United States",
  },
  {
    id: 160,
    name: "Maine Maritime Academy",
    address: "Maine",
    country: "United States",
  },
  {
    id: 161,
    name: "Manhattan College",
    address: "New York",
    country: "United States",
  },
  {
    id: 162,
    name: "Marquette University",
    address: "Wisconsin",
    country: "United States",
  },
  {
    id: 163,
    name: "Massachusetts College of Pharmacy & Health Sciences",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 164,
    name: "Massachusetts Institute of Technology - MIT",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 165,
    name: "Massachusetts Maritime Academy",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 166,
    name: "McMaster University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 167,
    name: "Medical University of South Carolina",
    address: "South Carolina",
    country: "United States",
  },
  {
    id: 168,
    name: "Mercer University",
    address: "Georgia",
    country: "United States",
  },
  {
    id: 169,
    name: "MGH Institute of Health Professions",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 170,
    name: "Miami University",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 171,
    name: "Michigan State University",
    address: "Michigan",
    country: "United States",
  },
  {
    id: 172,
    name: "Michigan Technological University",
    address: "Michigan",
    country: "United States",
  },
  {
    id: 173,
    name: "Middlebury College",
    address: "Vermont",
    country: "United States",
  },
  {
    id: 174,
    name: "Midwestern University",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 175,
    name: "Missouri University of Science and Technology",
    address: "Missouri",
    country: "United States",
  },
  {
    id: 176,
    name: "Montclair State University",
    address: "New Jersey",
    country: "United States",
  },

  {
    id: 177,
    name: "Mount Carmel College of Nursing",
    address: "Ohio",
    country: "United States",
  },

  {
    id: 178,
    name: "Mount Holyoke College",
    address: "Massachusetts",
    country: "United States",
  },

  {
    id: 179,
    name: "Mount Sinai School of Medicine",
    address: "New York",
    country: "United States",
  },

  {
    id: 180,
    name: "Nebraska Methodist College of Nursing and Allied Health",
    address: "Nebraska",
    country: "United States",
  },

  {
    id: 181,
    name: "New Jersey Institute of Technology",
    address: "New Jersey",
    country: "United States",
  },

  {
    id: 182,
    name: "New York Institute of Technology - Old Westbury",
    address: "New York",
    country: "United States",
  },

  {
    id: 183,
    name: "New York Law School",
    address: "New York",
    country: "United States",
  },

  {
    id: 184,
    name: "New York Medical College",
    address: "New York",
    country: "United States",
  },

  {
    id: 185,
    name: "New York University - NYU",
    address: "New York",
    country: "United States",
  },

  {
    id: 186,
    name: "North Carolina State University",
    address: "North Carolina",
    country: "United States",
  },

  {
    id: 187,
    name: "Northeastern University",
    address: "Massachusetts",
    country: "United States",
  },

  {
    id: 188,
    name: "Northeastern University Canada",
    address: "Ontario",
    country: "Canada",
  },

  {
    id: 189,
    name: "Northwestern College",
    address: "Iowa",
    country: "United States",
  },

  {
    id: 190,
    name: "Northwestern University",
    address: "Illinois",
    country: "United States",
  },

  {
    id: 191,
    name: "Nova Southeastern University",
    address: "Florida",
    country: "United States",
  },

  {
    id: 192,
    name: "Oberlin College ",
    address: "Ohio",
    country: "United States",
  },

  {
    id: 193,
    name: "Occidental College",
    address: "California",
    country: "United States",
  },

  {
    id: 194,
    name: "Ohio Northern University",
    address: "Ohio",
    country: "United States",
  },

  {
    id: 195,
    name: "Ohio State University - OSU",
    address: "Ohio",
    country: "United States",
  },

  {
    id: 196,
    name: "Oklahoma State University-Main Campus",
    address: "Oklahoma",
    country: "United States",
  },

  {
    id: 197,
    name: "Ontario College of Management and Technology",
    address: "Ontario",
    country: "Canada",
  },

  {
    id: 198,
    name: "Oregon Health & Science University",
    address: "Oregon",
    country: "United States",
  },

  {
    id: 199,
    name: "Oregon State University",
    address: "Oregon",
    country: "United States",
  },

  {
    id: 200,
    name: "Pace University - New York",
    address: "New York",
    country: "United States",
  },

  {
    id: 201,
    name: "Pacific University",
    address: "Oregon",
    country: "United States",
  },

  {
    id: 202,
    name: "Pennsylvania State University",
    address: "Pennsylvania",
    country: "United States",
  },

  {
    id: 203,
    name: "Pepperdine University",
    address: "California",
    country: "United States",
  },

  {
    id: 204,
    name: "Philadelphia College of Osteopathic Medicine",
    address: "Pennsylvania",
    country: "United States",
  },

  {
    id: 205,
    name: "Philadelphia University",
    address: "Pennsylvania",
    country: "United States",
  },

  {
    id: 206,
    name: "Phillips Beth Israel School of Nursing",
    address: "New York",
    country: "United States",
  },

  {
    id: 207,
    name: "Pitzer College",
    address: "California",
    country: "United States",
  },

  {
    id: 208,
    name: "Pomona College",
    address: "California",
    country: "United States",
  },
  {
    id: 209,
    name: "Princeton University",
    address: "New Jersey",
    country: "United States",
  },

  {
    id: 210,
    name: "Providence College",
    address: "  Rhode Island",
    country: "United States",
  },

  {
    id: 211,
    name: "Purdue University",
    address: "Indiana",
    country: "United States",
  },

  {
    id: 212,
    name: "Queens University at Kingston",
    address: "Ontario",
    country: "Canada",
  },

  {
    id: 213,
    name: " Quinnipiac University",
    address: "Connecticut",
    country: "United States",
  },

  {
    id: 214,
    name: "Rensselaer Polytechnic Institute",
    address: "New York",
    country: "United States",
  },

  {
    id: 215,
    name: "Research College of Nursing",
    address: "Missouri",
    country: "United States",
  },

  {
    id: 216,
    name: "Resurrection University",
    address: "Illinois",
    country: "United States",
  },

  {
    id: 217,
    name: "Rhodes College",
    address: "Tennessee",
    country: "United States",
  },

  {
    id: 218,
    name: "Rice University",
    address: "Texas",
    country: "United States",
  },

  {
    id: 219,
    name: "Robert Morris University",
    address: "Pennsylvania",
    country: "United States",
  },

  {
    id: 220,
    name: "Rochester Institute of Technology",
    address: "New York",
    country: "United States",
  },

  {
    id: 221,
    name: "Rollins College",
    address: "Florida",
    country: "United States",
  },

  {
    id: 222,
    name: "Rosalind Franklin University of Medicine and Science",
    address: "Illinois",
    country: "United States",
  },

  {
    id: 223,
    name: "Roseman University of Health Sciences",
    address: "Nevada",
    country: "United States",
  },

  {
    id: 224,
    name: "Rowan University",
    address: "New Jersey",
    country: "United States",
  },

  {
    id: 225,
    name: "Rush University",
    address: "Illinois",
    country: "United States",
  },

  {
    id: 226,
    name: "Rutgers University",
    address: "New Jersey",
    country: "United States",
  },

  {
    id: 227,
    name: "Saint Francis Medical Center College of Nursing",
    address: "Illinois",
    country: "United States",
  },

  {
    id: 228,
    name: "Saint Francis University",
    address: "Pennsylvania",
    country: "United States",
  },

  {
    id: 229,
    name: "Saint John Fisher College",
    address: "New York",
    country: "United States",
  },

  {
    id: 230,
    name: "Saint Louis University-Main Campus",
    address: "Missouri",
    country: "United States",
  },

  {
    id: 231,
    name: "Saint Luke's College of Health Sciences",
    address: "Missouri",
    country: "United States",
  },

  {
    id: 232,
    name: "Saint Mary's College of California",
    address: "California",
    country: "United States",
  },

  {
    id: 233,
    name: "Samford University",
    address: "Alabama",
    country: "United States",
  },

  {
    id: 234,
    name: "Samuel Merritt University",
    address: "California",
    country: "United States",
  },

  {
    id: 235,
    name: "San Diego State University - SDSU",
    address: "California",
    country: "United States",
  },

  {
    id: 236,
    name: "Santa Clara University",
    address: "California",
    country: "United States",
  },

  {
    id: 237,
    name: "Scripps College",
    address: "California",
    country: "United States",
  },

  {
    id: 238,
    name: "Seattle Pacific University",
    address: "Washington",
    country: "United States",
  },

  {
    id: 239,
    name: "Seattle University",
    address: "Washington",
    country: "United States",
  },

  {
    id: 240,
    name: "Sentara College of Health Sciences",
    address: "Virginia",
    country: "United States",
  },

  {
    id: 241,
    name: "Seton Hall University",
    address: "New Jersey",
    country: "United States",
  },

  {
    id: 242,
    name: "Seton Hill University",
    address: "Pennsylvania",
    country: "United States",
  },

  {
    id: 243,
    name: "Sewanee - The University of the South",
    address: "Tennessee",
    country: "United States",
  },

  {
    id: 244,
    name: "Shenandoah University",
    address: "Virginia",
    country: "United States",
  },

  {
    id: 245,
    name: "Simmons College",
    address: "Massachusetts",
    country: "United States",
  },

  {
    id: 246,
    name: "Skidmore College",
    address: "New York",
    country: "United States",
  },
  {
    id: 247,
    name: "Smith College",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 248,
    name: " Soka University of America",
    address: "California",
    country: "United States",
  },
  {
    id: 249,
    name: "South Dakota School of Mines and Technology",
    address: "South Dakota",
    country: "United States",
  },
  {
    id: 250,
    name: "South Dakota State University",
    address: "South Dakota",
    country: "United States",
  },
  {
    id: 251,
    name: "Southern Methodist University - SMU",
    address: "Texas",
    country: "United States",
  },
  {
    id: 252,
    name: "St John's University-New York",
    address: "New York",
    country: "United States",
  },
  {
    id: 253,
    name: "St Louis College of Pharmacy",
    address: "Missouri",
    country: "United States",
  },
  {
    id: 254,
    name: "St Luke's College",
    address: "Iowa",
    country: "United States",
  },
  {
    id: 255,
    name: "St. James School of Medicine | Saint James School of Medicine",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 256,
    name: "Stanford University",
    address: "California",
    country: "United States",
  },
  {
    id: 257,
    name: "Stetson University",
    address: "Florida",
    country: "United States",
  },
  {
    id: 258,
    name: "Stevens Institute of Technology",
    address: "New Jersey",
    country: "United States",
  },
  {
    id: 259,
    name: "Stonehill College",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 260,
    name: "Suffolk University",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 261,
    name: "SUNY at Albany",
    address: "New York",
    country: "United States",
  },
  {
    id: 262,
    name: "SUNY at Binghamton",
    address: "New York",
    country: "United States",
  },
  {
    id: 263,
    name: "SUNY at Stony Brook",
    address: " New York",
    country: "United States",
  },
  {
    id: 264,
    name: "SUNY College of Environmental Science and Forestry",
    address: "New York",
    country: "United States",
  },
  {
    id: 265,
    name: "SUNY Downstate Medical Center",
    address: "New York",
    country: "United States",
  },
  {
    id: 266,
    name: "Swarthmore College",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 267,
    name: "Syracuse University",
    address: "New York",
    country: "United States",
  },
  {
    id: 268,
    name: "Taylor University",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 269,
    name: "Teachers College at Columbia University",
    address: "New York",
    country: "United States",
  },
  {
    id: 270,
    name: "Temple University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 271,
    name: "Texas A&M University",
    address: "Texas",
    country: "United States",
  },
  {
    id: 272,
    name: "Texas Christian University - TCU",
    address: "Texas",
    country: "United States",
  },
  {
    id: 273,
    name: "Texas Tech University",
    address: "Texas",
    country: "United States",
  },
  {
    id: 274,
    name: "Texas Tech University Health Sciences Center",
    address: "Texas",
    country: "United States",
  },
  {
    id: 275,
    name: "The Citadel",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 276,
    name: "The College of New Jersey",
    address: "New Jersey",
    country: "United States",
  },
  {
    id: 277,
    name: "The New School",
    address: "New York",
    country: "United States",
  },
  {
    id: 278,
    name: "The University of Alabama",
    address: "Alabama",
    country: "United States",
  },
  {
    id: 279,
    name: "The University of Texas at Dallas",
    address: "Texas",
    country: "United States",
  },
  {
    id: 280,
    name: "The University of Texas Health Science - San Antonio",
    address: "Texas",
    country: "United States",
  },
  {
    id: 281,
    name: "The University of Texas Health Science Center at Houston",
    address: "Texas",
    country: "United States",
  },
  {
    id: 282,
    name: "The University of Texas MD Anderson Cancer Center",
    address: "Texas",
    country: "United States",
  },
  {
    id: 283,
    name: "The University of Texas Medical Branch",
    address: "Texas",
    country: "United States",
  },
  {
    id: 284,
    name: "The University of Texas Rio Grande Valley",
    address: "Texas",
    country: "United States",
  },
  {
    id: 285,
    name: "The University of Virginia's College at Wise",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 286,
    name: "Thomas Aquinas College",
    address: "California",
    country: "United States",
  },
  {
    id: 287,
    name: "Thomas Jefferson University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 288,
    name: "Thunderbird School of Global Management",
    address: "Arizona",
    country: "United States",
  },
  {
    id: 289,
    name: "Toronto Metropolitan University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 290,
    name: "Touro University California",
    address: "California",
    country: "United States",
  },
  {
    id: 291,
    name: "Towson University",
    address: "Maryland",
    country: "United States",
  },
  {
    id: 292,
    name: "Trent University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 293,
    name: "Trinity College",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 294,
    name: "Trinity University",
    address: "Texas",
    country: "United States",
  },
  {
    id: 295,
    name: "Truman State University",
    address: "Missouri",
    country: "United States",
  },
  {
    id: 296,
    name: "Tufts University",
    address: " Massachusetts",
    country: "United States",
  },
  {
    id: 297,
    name: "Tulane University",
    address: "Louisiana",
    country: "United States",
  },
  {
    id: 298,
    name: "Union College (New York)",
    address: "New York",
    country: "United States",
  },
  {
    id: 299,
    name: "Union University",
    address: "Tennessee",
    country: "United States",
  },
  {
    id: 300,
    name: "United States Merchant Marine Academy",
    address: "New York",
    country: "United States",
  },
  {
    id: 301,
    name: "University at Buffalo",
    address: "New York",
    country: "United States",
  },
  {
    id: 302,
    name: "University of Alabama at Birmingham",
    address: "Alabama",
    country: "United States",
  },
  {
    id: 303,
    name: "University of Alberta",
    address: "Alberta",
    country: "Canada",
  },
  {
    id: 304,
    name: "University of Arizona",
    address: "Arizona",
    country: "United States",
  },
  {
    id: 305,
    name: "University of Arkansas",
    address: "Arkansas",
    country: "United States",
  },
  {
    id: 306,
    name: "University of Arkansas for Medical Sciences",
    address: "Arkansas",
    country: "United States",
  },
  {
    id: 307,
    name: "University of Calgary",
    address: "Alberta",
    country: "Canada",
  },
  {
    id: 308,
    name: "University of California - Berkeley",
    address: "California",
    country: "United States",
  },
  {
    id: 309,
    name: "University of California - Davis",
    address: "California",
    country: "United States",
  },
  {
    id: 310,
    name: "University of California - Irvine",
    address: "California",
    country: "United States",
  },
  {
    id: 311,
    name: "University of California - Los Angeles - UCLA",
    address: "California",
    country: "United States",
  },
  {
    id: 312,
    name: "University of California - Merced",
    address: "California",
    country: "United States",
  },
  {
    id: 313,
    name: "University of California - Riverside",
    address: "California",

    country: "United States",
  },
  {
    id: 314,
    name: "University of California - San Diego - UCSD",
    address: "California",
    country: "United States",
  },
  {
    id: 315,
    name: "University of California - San Francisco - UCSF",
    address: "California",
    country: "United States",
  },
  {
    id: 316,
    name: "University of California - Santa Barbara - UCSB",
    address: "California",
    country: "United States",
  },
  {
    id: 317,
    name: "University of California - Santa Cruz",
    address: "California",
    country: "United States",
  },
  {
    id: 318,
    name: "University of California College of Law",
    address: "San Francisco",
    country: "United States",
  },
  {
    id: 319,
    name: "University of Central Florida",
    address: "Florida",
    country: "United States",
  },
  {
    id: 320,
    name: "University of Chicago (incl. Booth)",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 321,
    name: "University of Cincinnati-Main Campus",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 322,
    name: "University of Colorado at Boulder",
    address: "Colorado",
    country: "United States",
  },
  {
    id: 323,
    name: "University of Colorado at Colorado Springs",
    address: "Colorado",
    country: "United States",
  },
  {
    id: 324,
    name: "University of Colorado Denver",
    address: "Colorado",
    country: "United States",
  },
  {
    id: 325,
    name: "University of Connecticut - Uconn",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 326,
    name: "University of Dayton",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 327,
    name: "University of Delaware",
    address: "Delaware",
    country: "United States",
  },
  {
    id: 328,
    name: "University of Denver",
    address: "Colorado",
    country: "United States",
  },
  {
    id: 329,
    name: "University of Detroit Mercy",
    address: "Michigan",
    country: "United States",
  },
  {
    id: 330,
    name: "University of Florida",
    address: "Florida",
    country: "United States",
  },
  {
    id: 331,
    name: "University of Georgia",
    address: "Georgia",
    country: "United States",
  },
  {
    id: 332,
    name: "University of Guelph",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 333,
    name: "University of Hartford",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 334,
    name: "University of Hawaii at Manoa",
    address: "Hawaii",
    country: "United States",
  },
  {
    id: 335,
    name: "University of Houston",
    address: "Texas",
    country: "United States",
  },
  {
    id: 336,
    name: "University of Idaho",
    address: "Idaho",
    country: "United States",
  },
  {
    id: 337,
    name: "University of Illinois at Chicago",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 338,
    name: "University of Illinois at Urbana-Champaign",
    address: "Illinois",
    country: "United States",
  },
  {
    id: 339,
    name: "University of Iowa",
    address: "Iowa",
    country: "United States",
  },
  {
    id: 340,
    name: "University of Kansas",
    address: "Kansas",
    country: "United States",
  },
  {
    id: 341,
    name: "University of Kentucky",
    address: "Kentucky",
    country: "United States",
  },
  {
    id: 342,
    name: "University of La Verne",
    address: "California",
    country: "United States",
  },
  {
    id: 343,
    name: "University of Lethbridge",
    address: "Alberta",
    country: "Canada",
  },
  {
    id: 344,
    name: "University of Louisville",
    address: "Kentucky",
    country: "United States",
  },
  {
    id: 345,
    name: "University of Maine",
    address: "Maine",
    country: "United States",
  },
  {
    id: 346,
    name: "University of Maryland - Baltimore",
    address: "Maryland",
    country: "United States",
  },
  {
    id: 347,
    name: "University of Maryland - Baltimore County",
    address: "Maryland",
    country: "United States",
  },
  {
    id: 348,
    name: "University of Maryland - College Park",
    address: "Maryland",
    country: "United States",
  },
  {
    id: 349,
    name: "University of Massachusetts Amherst - UMass Amherst",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 350,
    name: "University of Massachusetts Medical School (UMMS)",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 351,
    name: "University of Massachusetts-Lowell",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 352,
    name: "University of Miami",
    address: "Florida",
    country: "United States",
  },
  {
    id: 353,
    name: "University of Michigan - Ann Arbor",
    address: "Michigan",
    country: "United States",
  },
  {
    id: 354,
    name: "University of Minnesota - Twin Cities",
    address: "Minnesota",
    country: "United States",
  },
  {
    id: 355,
    name: "University of Missouri - Columbia",
    address: "Missouri",
    country: "United States",
  },
  {
    id: 356,
    name: "University of Mount Union",
    address: "Ohio",
    country: "United States",
  },
  {
    id: 357,
    name: "University of Nebraska - Lincoln",
    address: "Nebraska",
    country: "United States",
  },
  {
    id: 358,
    name: "University of Nebraska Medical Center",
    address: "Nebraska",
    country: "United States",
  },
  {
    id: 359,
    name: "University of New Brunswick",
    address: "New Brunswick",
    country: "Canada",
  },
  {
    id: 360,
    name: "University of New England",
    address: "Maine",
    country: "United States",
  },
  {
    id: 361,
    name: "University of New Hampshire",
    address: "New Hampshire",
    country: "United States",
  },
  {
    id: 362,
    name: "University of North Carolina at Chapel Hill - UNC",
    address: "North Carolina",
    country: "United States",
  },
  {
    id: 363,
    name: "University of North Carolina Wilmington",
    address: "North Carolina",
    country: "United States",
  },
  {
    id: 364,
    name: "University of North Dakota",
    address: "North Dakota",
    country: "United States",
  },
  {
    id: 365,
    name: "University of North Texas Health Science Center at Fort Worth",
    address: "Texas",
    country: "United States",
  },
  {
    id: 366,
    name: "University of Northern Iowa",
    address: "Iowa",
    country: "United States",
  },
  {
    id: 367,
    name: "University of Notre Dame",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 368,
    name: "University of Oklahoma",
    address: "Oklahoma",
    country: "United States",
  },
  {
    id: 369,
    name: "University of Oregon",
    address: "Oregon",
    country: "United States",
  },
  {
    id: 370,
    name: "University of Ottawa",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 371,
    name: "University of Pennsylvania (including Wharton)",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 372,
    name: "University of Pittsburgh",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 373,
    name: "University of Portland",
    address: "Oregon",
    country: "United States",
  },
  {
    id: 374,
    name: "University of Puerto Rico-Medical Sciences",
    address: "Puerto Rico",
    country: "United States",
  },
  {
    id: 375,
    name: "University of Rhode Island",
    address: "Rhode Island",
    country: "United States",
  },
  {
    id: 376,
    name: "University of Richmond",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 377,
    name: "University of Rochester",
    address: "New York",
    country: "United States",
  },
  {
    id: 378,
    name: "University of Saint Joseph",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 379,
    name: "University of San Diego - USD",
    address: "California",
    country: "United States",
  },
  {
    id: 380,
    name: "University of San Francisco",
    address: "California",
    country: "United States",
  },
  {
    id: 381,
    name: "University of Saskatchewan",
    address: "Saskatchewan",
    country: "United States",
  },
  {
    id: 382,
    name: "University of Scranton",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 383,
    name: "University of South Carolina - Columbia",
    address: "South Carolina",
    country: "United States",
  },
  {
    id: 384,
    name: "University of South Dakota",
    address: "South Dakota",
    country: "United States",
  },
  {
    id: 385,
    name: "University of South Florida",
    address: "Florida",
    country: "United States",
  },
  {
    id: 386,
    name: "University of Southern California - USC",
    address: "California",
    country: "United States",
  },
  {
    id: 387,
    name: "University of St. Thomas ",
    address: "Minnesota",
    country: "United States",
  },
  {
    id: 388,
    name: "University of Tennessee - Knoxville",
    address: "Tennessee",
    country: "United States",
  },
  {
    id: 389,
    name: "University of Texas at Austin - UT Austin",
    address: "Texas",
    country: "United States",
  },
  {
    id: 390,
    name: "University of Texas Southwestern Medical Center at Dallas",
    address: "Texas",
    country: "United States",
  },
  {
    id: 391,
    name: "University of the Pacific",
    address: "California",
    country: "United States",
  },
  {
    id: 392,
    name: "University of the Potomac",
    address: "DC",
    country: "United States",
  },
  {
    id: 393,
    name: "University of the Sciences",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 394,
    name: "University of Toronto",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 395,
    name: "University of Tulsa",
    address: "Oklahoma",
    country: "United States",
  },
  {
    id: 396,
    name: "University of Utah",
    address: "Utah",
    country: "United States",
  },
  {
    id: 397,
    name: "University of Vermont",
    address: "Vermont",
    country: "United States",
  },
  {
    id: 398,
    name: "University of Virginia",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 399,
    name: "University of Washington- Seattle",
    address: "Washington",
    country: "United States",
  },
  {
    id: 400,
    name: "University of Waterloo",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 401,
    name: "University of Windsor",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 402,
    name: "University of Wisconsin - Madison",
    address: "Wisconsin",
    country: "United States",
  },
  {
    id: 403,
    name: "University of Wyoming",
    address: "Wyoming",
    country: "United States",
  },
  {
    id: 404,
    name: "Utah College of Dental Hygiene",
    address: "Utah",
    country: "United States",
  },
  {
    id: 405,
    name: "Valparaiso University",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 406,
    name: "Vanderbilt University",
    address: "Tennessee",
    country: "United States",
  },
  {
    id: 407,
    name: "Vassar College",
    address: "New York",
    country: "United States",
  },
  {
    id: 408,
    name: "Villanova University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 409,
    name: "Virginia Commonwealth University",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 410,
    name: "Virginia Polytechnic Institute and State University",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 411,
    name: "Wake Forest University",
    address: "North Carolina",
    country: "United States",
  },
  {
    id: 412,
    name: "Washington and Lee University",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 413,
    name: "Washington State University",
    address: "Washington",
    country: "United States",
  },
  {
    id: 414,
    name: "Washington University in St Louis",
    address: "Missouri",
    country: "United States",
  },
  {
    id: 415,
    name: "Wellesley College",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 416,
    name: "Wesleyan University",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 417,
    name: "Western University - University of Western Ontario",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 418,
    name: "Western University of Health Sciences",
    address: "California",
    country: "United States",
  },
  {
    id: 419,
    name: "Western Washington University",
    address: "Washington",
    country: "United States",
  },
  {
    id: 420,
    name: "Whitman College",
    address: "Washington",
    country: "United States",
  },
  {
    id: 421,
    name: "Whitworth University",
    address: "Washington",
    country: "United States",
  },
  {
    id: 422,
    name: "Widener University - Main Campus",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 423,
    name: "Wilfrid Laurier University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 424,
    name: "William Jessup University",
    address: "California",
    country: "United States",
  },
  {
    id: 425,
    name: "Williams College",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 426,
    name: "Worcester Polytechnic Institute",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 427,
    name: "Yale University",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 428,
    name: "Yeshiva University",
    address: "New York",
    country: "United States",
  },
  {
    id: 429,
    name: "York University",
    address: "Ontario",
    country: "Canada",
  },
];
