import React, { useState } from "react";
import { MyflightOffer } from "./data.js";
import { GiAirplaneDeparture } from "react-icons/gi";
import { CgCalendarDates } from "react-icons/cg";
import { Simplemodal } from "../helpers/modal/simplifiedmodal.js";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
// import { RadioOption } from "../inputs/radiobutton.js";
// import { Platformbutton } from "../helpers/button/button.js";
// import ngnwallet from "../../assets/icons/ngnwallet.svg";

export const Flightoffer = (props) => {
  const [showModal, setShowModal] = useState(false);

  const [subT, setsubT] = useState("");
  const [image, setImg] = useState(null);
  const [packageTitle, setPackageTitle] = useState("");
  return (
    <>
      <div className="w-full  px-4 md:px-8 py-7  lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-24 lg:pt-28  ">
        <div className="vlg:container  vlg:mx-auto ">
          <h1 className="mb-6  text-3xl text-headcol font-fontSemi text-center">
            Exciting Offers for You
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2  xlg:grid-cols-3 gap-6 lg:gap-8">
            {MyflightOffer.map(
              ({
                id,
                img,
                title,
                text,
                date,
                buttonText,
                offer,
                price,
                subTitle,
              }) => {
                return (
                  <div
                    key={id}
                    className="border-[1.25px]  border-[#D9D9D9] p-2 lg:p-4 rounded-[10px]"
                  >
                    <div className="relative h-[10rem] ">
                      <article className="z-10 ">
                        <p className=" left-2 top-2 w-32 absolute bg-[#DFFFD0] text-[#7EC65C] text-center py-1 text-[14px] rounded-[5px] font-[300]">
                          {offer}
                        </p>
                        <p className=" bottom-2 left-2 w-32 absolute bg-white text-center rounded-[5px] py-1">
                          {price}
                        </p>
                      </article>
                      <img
                        src={img}
                        alt=""
                        className="w-full z-5  h-full rounded-[10px] object-cover"
                      />
                    </div>

                    <div className="flex flex-col justify-between h-[14rem] lg:h-[14rem] xxl:h-[15rem] vlg:h-[13rem]">
                      <div>
                        <p className="mt-2 text-[16px] font-[600]">{title}</p>
                        <p className="my-2">{text}</p>
                      </div>
                      <div>
                        <p className="text-[13px] mb-2 flex items-center">
                          <CgCalendarDates className="mr-2" />
                          {date}
                        </p>

                        {offer === "Adventure" ? (
                          <p
                            onClick={() => {
                              setShowModal(true);
                              setsubT(subTitle);
                              setImg(img);
                              setPackageTitle(title);
                            }}
                            className="normal-case text-white rounded-lg cursor-pointer lg:w-64 bg-vestigreen transition w-64 flex  duration-700 ease-in-out hover:bg-[#3E6F26] p-4 text-base items-center "
                          >
                            Learn More{" "}
                            {/* <BsArrowRightShort className="text-[25px] flight-icon" /> */}
                            <GiAirplaneDeparture className="text-[25px] flight-icon" />
                          </p>
                        ) : (
                          <p
                            className="normal-case text-white rounded-lg lg:w-64 bg-vestigreen  cursor-pointer transition w-64 flex  duration-700 ease-in-out hover:bg-[#3E6F26] p-4 text-base"
                            onClick={() => props.setShowCurrencyModal(true)}
                          >
                            {buttonText}
                            <GiAirplaneDeparture className="text-[25px] flight-icon" />
                          </p>
                          // <a
                          //   href=" https://app.wevesti.com/bookflight"
                          //   target="_blanck"
                          //   className="normal-case text-white rounded-lg lg:w-64 bg-vestigreen transition w-64 flex  duration-700 ease-in-out hover:bg-[#3E6F26] p-4 text-base"
                          // >
                          //   {buttonText}
                          //   <GiAirplaneDeparture className="text-[25px] flight-icon" />
                          // </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      <Simplemodal onClick={() => setShowModal(false)} open={showModal}>
        <div
          className="relative
      ] mb-2 h-[17rem]"
        >
          <article className="z-10 ">
            {/* <p className=" left-2 top-1 w-32 absolute bg-[#DFFFD0] text-[#7EC65C] text-center py-1 text-[14px] rounded-[5px] font-[300]">
              Adventure
            </p> */}
            {/* <p className=" bottom-2 left-2 w-32 absolute bg-white text-center rounded-[5px] py-1">
              Price
            </p> */}
          </article>
          <img
            src={image}
            alt=""
            className="w-full z-5  h-full rounded-[10px] object-cover"
          />
        </div>

        {/* <div className="mb-2">
          <img
            src={image}
            alt=""
            className="w-full h-[12rem] object-cover rounded-[10px]"
          />
        </div> */}
        <h1 className=" text-vestigreen  flex items-center">
          <span className="shimmer text-[24px]">{packageTitle} </span>

          <GiAirplaneDeparture className="text-[25px] flight-icon" />
        </h1>
        <p className="mt-2 text-[14px]">{subT}</p>

        <p className="mt-3 text-[14px]">
          <span className="font-[600]">Total package, excluding Flights:</span>{" "}
          Adult-N900,000, Adult (sharing)- N700,000 Child below 18years:
          N350,000
        </p>

        <p className="mt-3 text-[14px]">
          <span className="font-[600]">Travel dates:</span> Proposed travel
          dates: 20th October to 26th October, 2023
        </p>

        <p className="mt-3 text-[12px]">
          For more enquiries please contact{" "}
          <span className=" text-[11px] text-vestigreen flex gap-2 items-center mt-1">
            <BsFillTelephoneFill />
            09018095469
          </span>
          <span className=" text-[11px] text-vestigreen flex gap-2 items-center mt-1">
            <MdEmail />
            flight@wevesti.com
          </span>
        </p>
      </Simplemodal>
    </>
  );
};
