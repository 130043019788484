import { Routes, Route, useLocation } from "react-router-dom";
// import { Suspense } from "react";
import Home from "./pages/Home";
import VirtualCard from "./pages/VirtualCard";
// eslint-disable-next-line
import GlobalGengCard from "./pages/GlobalGengCard";
import WalletsPage from "./pages/WalletsPage";
import AboutUs from "./pages/AboutUs";
import CareersPage from "./pages/CareersPage";
import Disclosures from "./pages/Disclosures";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Aml from "./pages/Aml";
import MigrationFries from "./pages/MigrationFries";
import MigrationServices from "./pages/MigrationServices";
import NigeriaHome from "./pages/NG/NigeriaHome";
import ZambiaHome from "./pages/ZM/ZambiaHome";
import GhanaHome from "./pages/GH/GhanaHome";
import MexicoHome from "./pages/MX/MexicoHome";
import ScrollToTop from "./components/ScrollToTop";
import MigrationPost from "./pages/MigrationPost";
import Faqs from "./pages/Faqs";
import NewsRoom from "./pages/NewsRoom";
import Team from "./pages/Team";
import TestimonialPage from "./pages/TestimonialPage";
import FinancialServices from "./pages/FinancialServices";
import CardsPage from "./pages/CardsPage";
import ReactGA from "react-ga";
import MigrationFeesPayment from "./components/MerchantFees/MigrationFeesPayment";
import ElementLandingPage from "./components/ElementLandingPage/elementlandingpage";
import FlightLandingPage from "./components/FlightLandingPage/flightlandingpage";
import "./assetlinks.json";
// import MigrationFeesPayment from "./components/MigrationFeesPayment";

import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect, useState } from "react";
import O1visa from "./pages/O1visa";
import EB1visa from "./pages/EB1visa";
import EBNIW from "./pages/EBNIWvisa";
import Schools from "./pages/Schools";
import IframeComponent from "./components/Iframe.mia";
import FloatingButton from "./components/floatingbutton/FloatingButton";
// import Miai from "./pages/Miai";
import MiaHome from "./pages/MiaHome";

import H1BVisa from "./pages/H1BVisa";
import Miailanding from "./components/Miai/miailanding";
import MiaiPolicies from "./components/Miai/miaiPolicies";
import MiaiPrivacyPolicy from "./components/Miai/PrivacyPolicy";
import Tos from "./components/Miai/Tos";

const queryClient = new QueryClient();

ReactGA.initialize("G-7E2M5N2KPP");
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [showIframe, setShowIframe] = useState(false);

  const mylocation = useLocation();
  const { pathname } = mylocation;
  const splitLocation = pathname.split("/");

  return (
    <>
      {splitLocation[1] === "element" ? (
        <></>
      ) : (
        <FloatingButton
          shape="circle"
          type="default"
          title="Miai"
          onClick={() => setShowIframe(true)}
        />
      )}

      {showIframe && (
        <div className="popup">
          {<IframeComponent onClick={() => setShowIframe(false)} />}
        </div>
      )}

      {/* <div className="fixed z-[10000] bg-[#16216B] w-[24rem] bottom-0 top-0 right-0 h-[100vh]">
        <p>Miai</p>
      </div> */}

      {splitLocation[1] === "element" ? <></> : <Miailanding />}

      <QueryClientProvider client={queryClient}>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/virtual-card" element={<VirtualCard />} />
          <Route path="/globalgeng-card" element={<GlobalGengCard />} />
          <Route path="/wallets" element={<WalletsPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/team" element={<Team />} />
          <Route path="/tos" element={<Disclosures />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/aml" element={<Aml />} />
          <Route path="/migration-fries" element={<MigrationFries />} />
          <Route path="/migration-services" element={<MigrationServices />} />
          <Route path="/financial-services" element={<FinancialServices />} />
          <Route path="/cards" element={<CardsPage />} />
          <Route path="/testimonials" element={<TestimonialPage />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/news-room" element={<NewsRoom />} />
          <Route path="/merchant-fees" element={<MigrationFeesPayment />} />
          <Route path="/ng" element={<NigeriaHome />} />
          <Route path="/gh" element={<GhanaHome />} />
          <Route path="/zm" element={<ZambiaHome />} />
          <Route path="/mx" element={<MexicoHome />} />
          <Route path="/migration-post/:postId" element={<MigrationPost />} />
          <Route path="/flight" element={<FlightLandingPage />} />
          {/* <Route path="*" element={<ErrorPage />} /> */}
          <Route path="/O1visa" element={<O1visa />} />
          <Route path="/EB1visa" element={<EB1visa />} />
          <Route path="/EB2NIWvisa" element={<EBNIW />} />
          <Route path="/HB1visa" element={<H1BVisa />} />
          <Route path="/schools_we_support" element={<Schools />} />
          <Route path="/miai" element={<MiaHome />} />
          <Route path="/miai/privacy-policy" element={<MiaiPrivacyPolicy />} />
          <Route path="/miai/tos" element={<Tos />} />
        </Routes>

        <Routes>
          <Route path="/element" element={<ElementLandingPage />} />
          <Route path="/config" element={"./assetlinks.json"} />
        </Routes>
        {/* </Suspense> */}
      </QueryClientProvider>
    </>
  );
}

export default App;
