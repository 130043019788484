import React from "react";
import { Navbar } from "./navbar";
import { Elementbanner } from "./elementbanner";
import { Elemententerprenuer } from "./elemententerprenuer";
import { OpenaccountOnline } from "./openaccountOnline";
import { ElementFeature } from "./elementFeature";
import { Downloadelement } from "./downloadelement";
import { Easygetstarted } from "./easygetstarted";
import Ebook from "./ebook";
import Review from "./review";
import { Footer } from "./footer";

const ElementLandingPage = () => {

  return (
    <div>
      <Navbar />
      <Elementbanner />
      <Elemententerprenuer />
      <OpenaccountOnline />
      <ElementFeature />
      <Downloadelement />
      <Easygetstarted />
      <Ebook />
      <Review />
      <Footer />
    </div>
  );
};

export default ElementLandingPage;
