import NavBar from "../../containers/NavBar";
import Footer from "../../containers/Footer";
import MiaiDoc from "./MiaiDocs";
import bannerPrivacyImg from "../../assets/miai/privacybannerimg.png";

const MiaiPrivacyPolicy = () => {
  return (
    <div>
      <NavBar />
      <MiaiDoc
        title="Miai Privacy Policy"
        date="Last Updated: Jan 02, 2024"
        link1="TERMS OF USE"
        subTitle="Learn about how we collect, protect, and use your data"
        link2="PRIVACY POLICY"
        path1="/miai/tos"
        path2="/miai/privacy-policy"
        addStyle="border-b-8 border-[#67A948]"
        img={bannerPrivacyImg}
        mainBody={
          <div className="text-justify text-[#14290A]">
            <h1 className="text-2xl font-fontReg">1. Content Policy</h1>
            <p className="py-10 ">
              Violation of usage policies, including illegal activity, violent
              content, adult content, and fraudulent activity, can lead to the
              suspension or termination of your account.
            </p>
            <h1 className="text-2xl font-fontReg">2. Privacy Policy</h1>
            <div className="py-10 flex flex-col gap-4">
              <p>
                <span className="font-[600]">Personal Information:</span> We
                collect Personal Information for account creation, user content,
                and communication purposes.
              </p>
              <p>
                <span className="font-[600]">Use of Personal Information:</span>{" "}
                We may use Personal Information for the following purposes:
              </p>

              <ul className="flex flex-col gap-4 mt-6">
                <li className="flex items-center gap-2">
                  <p className="bg-black w-2 h-2  rounded-full"></p> To provide,
                  administer, maintain, and/or analyze the Services;
                </li>
                <li className="flex items-center gap-2">
                  <p className="bg-black w-2 h-2  rounded-full"></p> To improve
                  our Services and conduct research;
                </li>
                <li className="flex items-center gap-2">
                  <p className="bg-black w-2 h-2  rounded-full"></p>To
                  communicate with you; including sending you information about
                  our Services and events;
                </li>
                <li className="flex items-center gap-2">
                  <p className="bg-black w-2 h-2  rounded-full"></p> To develop
                  new programs and services;
                </li>
                <li className="flex items-center gap-2">
                  <p className="bg-black w-2 h-2  rounded-full"></p> To prevent
                  fraud, criminal activity, or misuse of our Services, and to
                  protect the security of our IT systems, architecture, and
                  networks;
                </li>
                <li className="flex items-center gap-2">
                  <p className="bg-black w-2 h-2  rounded-full"></p> To carry
                  out business transfers; and
                </li>
                <li className="flex items-center gap-2">
                  <p className="bg-black w-2 h-2  rounded-full"></p> To comply
                  with legal obligations and legal process and to protect our
                  rights, privacy, safety, or property, and/or that of our
                  affiliates, you, or other third parties.
                </li>
              </ul>

              <p className="pt-10">
                <span className="font-[600]">
                  Aggregated or De-Identified Information:
                </span>{" "}
                We may aggregate or de-identify Personal Information so that it
                may no longer be used to identify you and use such information
                to analyze the effectiveness of our Services, to improve and add
                features to our Services, to conduct research, and for other
                similar purposes. In addition, from time to time, we may analyze
                the general behavior and characteristics of users of our
                Services and share aggregated information like general user
                statistics with third parties, publish such aggregated
                information, or make such aggregated information generally
                available. We may collect aggregated information through the
                Services, through cookies, and other means described in this
                Privacy Policy. We will maintain and use de-identified
                information in anonymous or de-identified form and we will not
                attempt to reidentify the information unless required by law.
              </p>

              <p className="pt-10">
                <span className="font-[600]">
                  Disclosure of personal information and Legal Requirements:{" "}
                </span>{" "}
                Personal Information may be shared with third parties to comply
                with legal obligations, protect rights or property, prevent
                fraud, or ensure the safety and security of our products and
                users. In certain circumstances we may provide your Personal
                Information to third parties without further notice to you
                unless required by the law:
              </p>

              <p className="pt-10">
                <span className="font-[600]">
                  Vendors and Service Providers:{" "}
                </span>{" "}
                To assist us in meeting business operations needs and to perform
                certain services and functions, we may provide Personal
                Information to vendors and service providers, including
                providers of hosting services, customer service vendors, cloud
                services, email communication software, web analytics services,
                and other information technology providers, among others.
                Pursuant to our instructions, these parties will access,
                process, or store Personal Information only in the course of
                performing their duties to us.
              </p>

              <p className="pt-10">
                <span className="font-[600]">Business Transfers: </span> If we
                are involved in strategic transactions, reorganization,
                bankruptcy, receivership, or transition of service to another
                provider (collectively, a “Transaction”), your Personal
                Information and other information may be disclosed in the
                diligence process with counterparties and others assisting with
                the Transaction and transferred to a successor or affiliate as
                part of that Transaction along with other assets.
              </p>

              <p className="pt-10">
                <span className="font-[600]">Legal Requirements: </span> We may
                share your Personal Information, including information about
                your interaction with our Services, with government authorities,
                industry peers, or other third parties (i) if required to do so
                by law or in the good faith belief that such action is necessary
                to comply with a legal obligation, (ii) to protect and defend
                our rights or property, (iii) if we determine, in our sole
                discretion, that there is a violation of our terms, policies, or
                the law; (iv) to detect or prevent fraud or other illegal
                activity; (v) to protect the safety, security, and integrity of
                our products, employees, or users, or the public, or (vi) to
                protect against legal liability.
              </p>

              <p className="pt-10">
                <span className="font-[600]">Affiliates: </span> We may disclose
                Personal Information to our affiliates, meaning an entity that
                controls, is controlled by, or is under common control with
                Miai. Our affiliates may use the Personal Information we share
                in a manner consistent with this Privacy Policy.
              </p>

              <p className="pt-10">
                <span className="font-[600]">
                  Other Users and Third Parties You Share Information With:{" "}
                </span>{" "}
                Certain features allow you to display or share information with
                other users or third parties. Be sure you trust any user or
                third party with whom you share information.
              </p>
            </div>
            <h1 className="text-2xl font-fontReg">3. Children</h1>
            <p className="py-10 ">
              Our Services are not directed at children under 18, and we do not
              knowingly collect their Personal Information.
            </p>
            <h1 className="text-2xl font-fontReg">
              4. Links to Other Websites
            </h1>
            <p className="py-10 ">
              Links to third-party sites are provided, and the privacy policies
              of these third-party sites and terms will govern the information
              shared on those sites.
            </p>
            <h1 className="text-2xl font-fontReg">
              5. Changes to Miai’s Policy
            </h1>
            <p className="py-10 ">
              This Privacy Policy may be updated, and changes will be posted on
              this page unless otherwise required by law.
            </p>
          </div>
        }
      />
      {/* <DownloadApp /> */}
      <Footer />
    </div>
  );
};

export default MiaiPrivacyPolicy;
