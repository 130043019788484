// import VestiMpower from "../assets/images/vesti-mpower.png";

export const latestNews = {
  img: "https://global.ariseplay.com/amg/www.thisdaylive.com/uploads/Screenshot_20231109-212253.jpg",
  date: "November 11th, 2023",
  title: "This Day ",
  desc: "How Immigrant Talents Can Foster An Inclusive Global Workforce",
  link: "https://www.thisdaylive.com/index.php/2023/11/08/how-immigrant-talents-can-foster-an-inclusive-global-workforce/",
};

export const previousNews = [
  {
    img: "https://sunnewsonline.com/ezoimgfmt/assets.sunnewsonline.com/wp-content/uploads/2023/11/thumbnail-11-2.jpg?ezimgfmt=ngcb1/notWebP",

    date: "November 10th, 2023",
    title: "Sun News",
    desc: "Vesti building AI copilot for immigrants",
    link: "https://sunnewsonline.com/vesti-building-ai-copilot-for-immigrants/",
  },
  {
    img: "",

    date: "October 14th, 2023",
    title: "Aridigest Fintech Review",
    desc: "Afridigest Fintech Review: One way to think about fintech in Africa",
    link: "https://open.substack.com/pub/afridigest/p/afr-week-41-2023?utm_campaign=post&utm_medium=web",
  },
  {
    img: "	https://x7d4c5z5.stackpathcdn.com/wp-content/uploads/tc/2023/10/0A7A0789-1050x700.jpg",

    date: "October 11th, 2023",
    title: "Techcabal",
    desc: "Techstars-backed migration fintech Vesti, launches in the United Kingdom",
    link: "https://techcabal.com/2023/10/11/techstars-backed-migration-fintgech-vesti-launches-in-the-united-kingdom/",
  },

  /// older
  {
    img: "https://x7d4c5z5.stackpathcdn.com/wp-content/uploads/tc/2023/10/Featured-Image.jpg",
    date: "October 7th, 2023",
    title: "Techcabal ",
    desc: "Vesti Unveils Cutting-Edge Migration Fintech Solution in Zambia",
    link: "https://techcabal.com/2023/10/07/vesti-unveils-cutting-edge-migration-fintech-solution-in-zambia/ ",
  },

  {
    img: "https://www.accesswire.com/users/newswire/images/789416/AJY3EnmRT1IENzQa8bNYekviVs7NLkPbiSwSUKAUJhLSmpFf-TEug94tfw5WAG101qbkKQ6hFVOisqEKy6HZALq9Z91mo4xq-YA5RiddJd8-7FHEOToS0XwTy7ujHJDFAgyBUE_kKJayjKFX8OQQxh8.jpg",

    date: "October 2nd, 2023",
    title: "Beardsley Farmers Elevator Company",
    desc: "Vesti Launches Element to Bank US-bound African Founders",
    link: "https://www.beardsleyfe.com/markets/stocks.php?article=accwirecq-2023-10-2-vesti-launches-element-to-bank-us-bound-african-founders",
  },
  {
    img: "	https://i0.wp.com/trenderhq.com/wp-content/uploads/2023/10/Vesti-Clear.jpg",

    date: "October 2nd, 2023",
    title: "TenderHQ",
    desc: "Vesti: Making Global Opportunities Accessible",
    link: "https://trenderhq.com/vesti-making-global-opportunities-accessible/",
  },

  {
    img: "https://cdn.ghanaweb.com/imagelib/pics/545/54532814.295.jpg",

    date: "September 21, 2023",
    title: "GhanaWeb",
    desc: "The significance of legal documentation in international travel: Insights from Ariyo-Agbaje Ifeoluwa",
    link: "https://www.ghanaweb.com/GhanaHomePage/features/The-significance-of-legal-documentation-in-international-travel-Insights-from-Ariyo-Agbaje-Ifeoluwa-1848332 ",
  },
  {
    img: "	https://assets.sunnewsonline.com/wp-content/uploads/2023/09/thumbnail-37.jpg",

    date: "September 15th, 2023",
    title: "Sun News",
    desc: "Financial inclusion for immigrants will boost economy of host countries",
    link: "https://sunnewsonline.com/financial-inclusion-for-immigrants-will-boost-economy-of-host-countries/",
  },

  {
    img: "https://www.bulabagh.com/wp-content/uploads/2023/08/Vesti-Grand-Launch-64-780x470.jpg",

    date: "August 11th, 2023",
    title: "Bulaba Gh",
    desc: "Vesti Launches Ghana Branch to help Immigrants and related Financial Support Services",
    link: "https://www.bulabagh.com/vesti-launches-ghana-branch-to-help-immigrants-and-related-financial-support-services/",
  },
  {
    img: "https://x7d4c5z5.stackpathcdn.com/wp-content/uploads/tc/2023/07/Element-intro-1024x1024.jpg",

    date: "July 17th, 2023",
    title: "Techcabal",
    desc: "Element by Vesti, a Digital Banking Solution Launched at Lagos Startup Week 2023",
    link: "https://techcabal.com/2023/07/17/element-by-vesti-a-digital-banking-solution-launched-at-lagos-startup-week-2023/",
  },
  {
    img: "https://sunnewsonline.com/ezoimgfmt/assets.sunnewsonline.com/wp-content/uploads/2023/07/thumbnail-11.jpg",

    date: "July 6th, 2023",
    title: "The Sun News",
    desc: "Using unified framework to tackle money laundering in Africa",
    link: "https://sunnewsonline.com/using-unified-framework-to-tackle-money-laundering-in-africa/",
  },
  {
    img: "https://techpoint.africa/wp-content/uploads/2023/06/lagos-startup-headline-scaled.jpg",

    date: "July 1st, 2023",
    title: "Techpoint",
    desc: "Vesti Reaffirms Commitment to Startup Ecosystem, Headlines Lagos Startup Week 2023",
    link: "https://techpoint.africa/2023/06/01/vesti-reaffirms-commitment-to-startup-ecosystem-headlines-lagos-startup-week-2023/",
  },

  {
    img: "https://sunnewsonline.com/ezoimgfmt/assets.sunnewsonline.com/wp-content/uploads/2023/05/thumbnail.png?ezimgfmt=rs:746x500/rscb1/ngcb1/notWebP",

    date: "May 17th, 2023",
    title: "The Sun",
    desc: "Vesti offers Maldives getaway to Hilda Baci",
    link: "https://thestreetjournal.org/vesti-app-offers-hilda-baci-all-expense-paid-trip-to-maldives-for-summer/",
  },

  {
    img: "https://cdn.vanguardngr.com/wp-content/uploads/2023/05/satbusvesti-pix-scaled.jpeg",

    date: "May 13th, 2023",
    title: "Vanguard",
    desc: "2023 Vesti Fair targets empowering the global citizen",
    link: "https://www.vanguardngr.com/2023/05/2023-vesti-fair-targets-empowering-the-global-citizen/",
  },
  {
    img: "https://x7d4c5z5.stackpathcdn.com/wp-content/uploads/tc/2023/05/book-a-flightt-768x768.jpg",

    date: "May 8th, 2023",
    title: "Techcabal",
    desc: "Vesti’s New Partnership Brings Low Prices on Flights to Users",
    link: "https://techcabal.com/2023/05/08/vestis-new-partnership-brings-low-prices-on-flights-to-users/",
  },
  {
    img: "https://technext24.com/wp-content/uploads/2023/05/Co-founder-and-CEO-of-Vesti.png",

    date: "May 21st, 2023",
    title: "Technext",
    desc: "Vesti’s Olusola Amusan is helping Nigerian students migrate abroad with loans, payments, advice",
    link: "https://technext24.com/2023/05/21/vesti-is-helping-students-migrate-to-us/ ",
  },
  {
    img: "https://x7d4c5z5.stackpathcdn.com/wp-content/uploads/tc/2023/04/Agy-mipango-pic-1.jpg",

    date: "April 20th, 2023",
    title: "Techcabal",
    desc: "15 founders building solutions to look out for in 2023",
    link: "https://techcabal.com/2023/04/20/15-founders-building-solutions-to-look-out-for-in-2023/",
  },
  {
    img: "	https://assets.sunnewsonline.com/wp-content/uploads/2023/04/Untitled-29.jpg",

    date: "April 2nd, 2023",
    title: "The Sun",
    desc: "Challenges African migrants face in accessing financial opportunities —Amusan, Vesti Technologies CEO",
    link: "https://sunnewsonline.com/challenges-african-migrants-face-in-accessing-financial-opportunities-amusan-vesti-technologies-ceo/#google_vignette",
  },

  {
    img: "",

    date: "21 March, 2023",
    title: "Tech Cabal",
    desc: "Vesti Technology Solutions Inc. and MPower Financing Announce Partnership",
    link: "https://techcabal.com/2023/03/21/vesti-technology-solutions-inc-and-mpower-financing-announce-partnership/",
  },
  {
    img: "",
    date: "7 March, 2023",
    title: "Technova Ghana",
    desc: "Vesti, A Financial Service Provider For Immigrants, Plans to Expand To Ghana In Q2 2023",
    link: "https://technovagh.com/vesti-a-financial-service-provider-for-immigrants-plans-to-expand-to-ghana-in-q2-2023/",
  },
  {
    img: "",
    date: "6 March, 2023",
    title: "Tech Point Africa",
    desc: "Vesti hints expansion to Ghana, Zambia and Mexico in Q2 to tackle Immigrants that are US bound",
    link: "https://techpoint.africa/2023/03/06/vesti-hints-expansion-to-ghana-zambia-and-mexico-in-q2-to-tackle-immigrants-that-are-us-bound/",
  },
  {
    img: "",
    date: "6 March, 2023",
    title: "Tech Cabal",
    desc: "Vesti hints expansion to Ghana, Zambia and Mexico in Q2 to tackle Immigrants that are US bound",
    link: "https://techcabal.com/2023/03/06/vesti-hints-expansion-to-ghana-zambia-and-mexico-in-q2-to-tackle-immigrants-that-are-us-bound/",
  },
  {
    img: "",
    date: "18 Feb, 2023",
    title: "This Day",
    desc: "Vesti Technologies Unveils Promo to Reward Users",
    link: "https://www.thisdaylive.com/index.php/2023/02/18/vesti-technologies-unveils-promo-to-reward-users/",
  },
  {
    img: "",
    date: "6 Feb, 2023",
    title: "SunNews Online",
    desc: "Vesti, Shuttlers partner to grow Nigeria’s transportation",
    link: "https://sunnewsonline.com/vesti-shuttlers-partner-to-grow-nigerias-transportation/",
  },
  {
    img: "",
    date: "5 Feb, 2023",
    title: "Guardian Newspaper",
    desc: "Our commitment to tailored payment products unwavering",
    link: "https://guardian.ng/business-services/our-commitment-to-tailored-payment-products-unwavering/",
  },
  {
    img: "",
    date: "31 Jan, 2023",
    title: "Business Day",
    desc: "Shuttlers, Vesti partner to ease commuting",
    link: "https://businessday.ng/companies/article/shuttlers-vesti-partner-to-ease-commuting/",
  },
  {
    img: "",
    date: "30 Jan, 2023",
    title: "Tech Cabal",
    desc: "Vesti, Shuttlers partner to increase brand equity",
    link: "https://techcabal.com/2023/01/30/vesti-shuttlers-partner-to-increase-brand-equity/",
  },
  {
    img: "",
    date: "26 Jan, 2022",
    title: "Tech Cabal",
    desc: "Migrant’s banking app, Vesti raises $500k in pre-seed funding",
    link: "https://techcabal.com/2022/01/26/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",
  },
  {
    img: "",
    date: "16 Dec, 2022",
    title: "The SunNews Online",
    desc: "Amusan accepted into Forbes Business Council",
    link: "https://sunnewsonline.com/amusan-accepted-into-forbes-business-council/",
  },
  {
    img: "",
    date: "11 May, 2021",
    title: "Techcity",
    desc: "Latest innovation by Amusan brothers",
    link: "https://www.techcityng.com/tag/latest-innovation-by-amusan-brothers/",
  },
  {
    img: "",
    date: "11 May, 2021",
    title: "Techcity",
    desc: "Vesti app wants to make payment and money management easier for migrants.",
    link: "https://www.techcityng.com/vesti-app-a-redefining-solution-for-peer-to-peer-payments-and-more-courtesy-of-the-amusan-brothers/",
  },
  {
    img: "",
    date: "10 May, 2021",
    title: "Techpoint",
    desc: "Amusan Brothers build Vesti app, a financial solution helping to solve peer-to-peer payments and migration related fees settlement",
    link: "https://techpoint.africa/2021/05/10/amusan-brothers-build-vesti-app-a-financial-solution-helping-to-solve-peer-to-peer-payments-and-migration-related-fees-settlement/",
  },
];
