import React, { useState, useRef, useEffect } from "react";
import Miai from "../../assets/miai/miaiImg.svg";
import Typed from "react-typed";

const Titles = [
  { title: "Interactive Q&A" },
  { title: "Visa Guidance" },
  { title: "Job Market Insights" },
  // { title: "Submit application" },
];

const ChatHistory = (props) => {
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  const handleTitleClick = (title) => {
    props.addMessage(title, true);
    // addMessage(inputText, true);

    setTimeout(() => {
      const response = props.generateChatbotResponse(props.inputText);
      props.addMessage(response, false);
    }, 500);
  };

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  function getFormattedTime() {
    const date = new Date();
    return date.toLocaleTimeString("en-US", { timeZone: userTimeZone });
  }

  // const formattedTime = getFormattedTime();

  return (
    <div
      ref={chatContainerRef}
      className={`px-[15px] py-[20px] lg:w-full h-[35vh] ${
        props.height === "100vh" ? "lg:h-[70vh]" : "lg:h-[10vh]"
      } overflow-y-auto `}
    >
      {/* <p className="text-center  text-[#fff] text-[11px] mb-6">Today</p> */}
      <div className="flex my-4 w-full">
        <div className="w-[100%] flex  gap-2">
          <img className="w-[25px] h-[25px] " src={Miai} alt="Miai" />

          <div className="w-full">
            <p className="leading-[20px] w-full bg-[#fff] p-2 text-[14px] rounded-b-[8px] rounded-tr-[8px]">
              {props.messages.length === 0 ? (
                <Typed
                  strings={[
                    "Hi there, what questions do you have about moving abroad?",
                  ]}
                  typeSpeed={100}
                  backSpeed={100}
                  loop
                />
              ) : (
                "Hi there, what questions do you have about moving abroad?"
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="w-[25px] h-[25px] "></div>
        <ul className="text-[#fff] text-[13px] flex flex-col gap-4">
          {Titles.map(({ title }) => {
            return (
              <li
                onClick={() => handleTitleClick(title)}
                className="underline cursor-pointer"
              >
                {title}
              </li>
            );
          })}
        </ul>
      </div>
      {props.messages.map((message, index) => {
        return (
          <div>
            {message.isUser ? (
              <div key={index} className="flex justify-end mb-6">
                <div>
                  <div className="w-[100%] flex items-center gap-2">
                    <div className="w-[25px] h-[25px] "></div>
                    <p className="leading-[20px] bg-[#97E373]  p-2 text-[14px] rounded-b-[8px] rounded-tl-[8px]">
                      {message.text}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} className="flex mb-4">
                <div className="w-[100%] flex  gap-2">
                  <img className="w-[25px] h-[25px] " src={Miai} alt="Miai" />

                  <div>
                    <p className="leading-[20px] bg-[#fff] p-2 text-[14px] rounded-b-[8px] rounded-tr-[8px]">
                      {props.generateChatbotResponse(message.text)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ChatHistory;
