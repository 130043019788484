// import VestiLogo from "../assets/logo-icons/vesti-logo.png";
import VestiLogo from "../assets/XmasLogo/XmasWhiteLogo.png";

import TwitterLogo from "../assets/logo-icons/twitter-logo.svg";
import LinkedinLogo from "../assets/logo-icons/linkedin-logo.svg";
import FacebookLogo from "../assets/logo-icons/facebook-logo.svg";
import InstagramLogo from "../assets/logo-icons/instagram-logo.svg";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useState } from "react";
import { openLink, openMail } from "../helpers/helpers";

const Footer = () => {
  const [show, setShow] = useState({ value: false, name: "" });
  const d = new Date();
  let year = d.getFullYear();
  const toggleShow = (name) => {
    setShow({ value: !show.value, name: name === show.name ? "" : name });
  };

  return (
    <>
      <div className="bg-[#060E42] text-[#ffffff] border-none w-full  px-4 md:px-8 py-7 lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-12 lg:pt-28">
        <div className="vlg:container  vlg:mx-auto pt-14 font-fontLight">
          <div className="grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-8 pb-20">
            <div>
              <div className="pr-[40px] cursor-pointer">
                <Link to="/">
                  <img className="w-[150px]" src={VestiLogo} alt="vesti-logo" />
                </Link>
              </div>
              <div className="pt-5">
                <ul className="flex">
                  <Link
                    to="https://www.twitter.com/Vestiofficial"
                    className="pl-4"
                  >
                    <img src={TwitterLogo} alt="twitter-icon" />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/vestiofficial"
                    className="pl-4"
                  >
                    <img src={LinkedinLogo} alt="linkedin-icon" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/profile.php?id=100088055724008&mibextid=ZbWKwL"
                    className="pl-4"
                  >
                    <img src={FacebookLogo} alt="facebook-icon" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/Vestiofficial"
                    className="pl-4"
                  >
                    <img src={InstagramLogo} alt="instagram-icon" />
                  </Link>
                </ul>
              </div>
            </div>
            <div className="">
              <ul className="flex flex-col">
                <li className="text-base font-fontSemi pb-3">PRODUCTS</li>
                {/* <p>Hello buddy</p> */}
                <div>
                  <div
                    className="text-sm font-fontLight pb-1 hover:font-fontSemi flex items-center cursor-pointer"
                    onClick={() => toggleShow("financial")}
                  >
                    <p>Financial Services</p>
                    {show.value && show.name === "financial" ? (
                      <RiArrowDropUpLine className="text-2xl" />
                    ) : (
                      <RiArrowDropDownLine className="text-2xl" />
                    )}
                  </div>
                  <div
                    className={`text-xs pl-2 ${
                      show.name !== "financial" && "hidden"
                    }`}
                  >
                    <ul className="flex flex-col gap-y-2">
                      <li>
                        <Link
                          to="/globalgeng-card"
                          className="text-xs font-fontLight hover:text-vestigreen hover:font-fontSemi"
                        >
                          GlobalGeng Card
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/virtual-card"
                          className="text-xs font-fontLight hover:text-vestigreen hover:font-fontSemi"
                        >
                          Virtual Dollar Card
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/wallets"
                          className="text-xs font-fontLight hover:text-vestigreen hover:font-fontSemi"
                        >
                          Vesti Wallets
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="pt-2">
                  <div
                    className="text-sm font-fontLight pb-1 hover:font-fontSemi flex items-center cursor-pointer"
                    onClick={() => toggleShow("visa")}
                  >
                    <p>Migration Services</p>
                    {show.value && show.name === "visa" ? (
                      <RiArrowDropUpLine className="text-2xl" />
                    ) : (
                      <RiArrowDropDownLine className="text-2xl" />
                    )}
                  </div>
                  <div
                    className={`text-xs pl-2 ${
                      show.name !== "visa" && "hidden"
                    }`}
                  >
                    <ul className="flex flex-col gap-y-2">
                      <li className=" hover:text-vestigreen hover:font-fontSemi ">
                        <Link to="/migration-services">Migration Services</Link>
                      </li>
                      <li className=" hover:text-vestigreen hover:font-fontSemi ">
                        <Link to="/O1visa">O-1 Visa</Link>
                      </li>
                      {/* <a
                        className="hover:text-vestigreen hover:font-fontSemi"
                        href="https://app.wevesti.com/o1visa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        O-1 Visa
                      </a> */}
                      <a
                        href="/EB1visa"
                        className="hover:text-vestigreen hover:font-fontSemi"
                      >
                        EB1 Visa
                      </a>
                      <a
                        href="/EB2NIWvisa"
                        className="hover:text-vestigreen hover:font-fontSemi"
                      >
                        EB2-NIW Visa
                      </a>
                      <a
                        href="/#"
                        className="hover:text-vestigreen hover:font-fontSemi"
                      >
                        UK Tech Nation Visa
                      </a>
                      <a
                        href="/#"
                        className="hover:text-vestigreen hover:font-fontSemi"
                      >
                        Canada Startup Visa
                      </a>
                      {/* <a
                        href="/H1-Bvisa"
                        className="hover:text-vestigreen hover:font-fontSemi"
                      >
                        H1-B Visa
                      </a> */}
                      <a
                        href="/schools_we_support"
                        className="hover:text-vestigreen hover:font-fontSemi"
                      >
                        Schools we support
                      </a>
                    </ul>
                  </div>
                </div>
              </ul>
            </div>
            <div>
              <ul className="flex flex-col">
                <li className="text-base font-fontSemi pb-3">COMPANY</li>
                <Link
                  to="/about-us"
                  className="text-sm font-fontLight pb-2 hover:text-vestigreen hover:font-fontSemi"
                >
                  About Us
                </Link>
                <Link
                  to="/careers"
                  className="text-sm pb-2 font-fontLight hover:text-vestigreen hover:font-fontSemi"
                >
                  Careers
                </Link>
                <Link
                  to="/news-room"
                  className="text-sm font-fontLight pb-2 hover:text-vestigreen hover:font-fontSemi"
                >
                  News Room
                </Link>
                <Link
                  to="/team"
                  className="text-sm font-fontLight hover:text-vestigreen hover:font-fontSemi"
                >
                  Team
                </Link>
              </ul>
            </div>
            <div>
              <ul className="flex flex-col">
                <li className="text-base font-medium pb-3 font-fontSemi">
                  LEGAL
                </li>
                <Link
                  to="/aml"
                  className="text-sm font-fontLight pb-2 hover:text-vestigreen hover:font-fontSemi"
                >
                  AML
                </Link>
                <Link
                  to="/faqs"
                  className="text-sm font-fontLight pb-2 hover:text-vestigreen hover:font-fontSemi"
                >
                  FAQ
                </Link>
                <Link
                  to="/tos"
                  className="text-sm font-fontLight pb-2 hover:text-vestigreen hover:font-fontSemi"
                >
                  Disclosures
                </Link>
                <Link
                  to="/privacy-policy"
                  className="text-sm font-fontLight pb-2 hover:text-vestigreen hover:font-fontSemi"
                >
                  Privacy Policy
                </Link>
              </ul>
            </div>
            <div>
              <ul className="flex flex-col">
                <li className="text-base font-medium pb-3 font-fontSemi">
                  CONTACT
                </li>
                <li
                  className="text-sm font-fontLight pb-2"
                  onClick={() => openMail("help@wevesti.com")}
                >
                  help@wevesti.com
                </li>
                <li
                  className="text-sm font-fontLight pb-2"
                  onClick={() => openLink("https://vesti.tawk.help")}
                >
                  Help Center
                </li>
              </ul>
            </div>
            <div>
              <ul className="flex flex-col">
                <li className="text-base pb-3 font-fontSemi">
                  LAGOS, (NIGERIA)
                </li>
                <li className="text-sm font-light pb-2">
                  30 Furo Ezimora street, Lekki Phase 1
                </li>
                <li className="text-base py-4 font-fontSemi">
                  DALLAS TEXAS (USA)
                </li>
                <li className="text-sm font-fontLight">
                  1301, S Bowen rd, Ste 450, Arlington, Tx 76013.
                </li>
              </ul>
            </div>
          </div>
          <div className="text-center pb-7 text-sm font-fontLight">
            <p>Copyrights &copy; Vesti {year}</p>
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<script>function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"1bb4ef4b4d850cb3d353214db6fadf2"})});</script>',
        }}
      />
    </>
  );
};

export default Footer;
